import React, { useEffect, useMemo, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';
import { COACH_SELECT, SPACE_SELECT } from '../../../../../../services/ConstHelper';
import { fetchApi } from '../../../../../../services/HTTP';
import { t } from '../../../../../../services/i18n';
import usePermissions from '../../../../../../services/Permissions';
import AdvanceSelect from '../../../../../common/AdvanceSelect';
import FormInput from '../../../../../common/FormInput';
import RepeatConfiguration from './RepeatConfiguration';
import useCheckPermissions from '../../../../../../Hooks/useCheckPermission';

const BlockedTime = props => {
    const { values, setFieldValue, touched, errors, handleChange } = props;
    const [coaches, setCoaches] = useState(null);
    const box = useSelector(state => state.box.box)
    const currentUser = useSelector(state => state.user.user)
    const { editType } = useSelector(state => state.stepper)
    const selectedLocation = useSelector((state) => state.userSettings.scheduleLocation);
    const { has, hasLock } = usePermissions()
    const { hasLimitedCoachViewSchedule } = useCheckPermissions()
    const locationOptions = box?.locations_box?.map(location => ({ value: location.id, label: location.location })) || [];
    const spacesByLocation = useMemo(() => box.locations_box.find(location => location.id === values.locations_box_fk)?.spaces.reduce((acc, space) => [...acc, {...space, label: space.name, value: space.id}],[]), [values.locations_box_fk]);

    useEffect(() => {
        fetchCoaches();
    }, []);

    useEffect(() => {
        if(selectedLocation && Object.keys(values).length && !values.locations_box_fk) {
            setFieldValue('locations_box_fk', selectedLocation.id)
        }
    }, [selectedLocation, values.locations_box_fk]);

    const fetchCoaches = async () => {
        let coachesRes = await fetchApi('getCoachesOfBox', 'get', null, false, true);
        coachesRes.forEach(coach => {
            coach.label = `${coach.first_name} ${coach.last_name}`
            coach.value = coach.user_fk
            return coach
        })
        setCoaches(coachesRes);
        if (editType || values.coach_fk) return;
        if (coachesRes.length === 1 && values.coach_fk) {
            setFieldValue('coach_fk', coachesRes[0].user_fk)
        }
        if (coachesRes.some(c => c.user_fk === currentUser.user_fk)) {
            setFieldValue('coach_fk', currentUser.user_fk)
        }
    }

    const onLocationChange = (val) => {
        const locationChange = val.value !== values.locations_box_fk
        setFieldValue('locations_box_fk', val.value)
        if (locationChange) {
            setFieldValue('spaces_id', null);
        }
    }

    if (!coaches) return <ActivityIndicator />

    return (
        <>
            <FormInput containerProps={{ mb: '2rem' }} label={t('coach')} width={'100%'}>
                <AdvanceSelect isDisabled={hasLimitedCoachViewSchedule} isClearable options={coaches} initValue={values.coach_fk} type={COACH_SELECT} target={{ name: 'coach_fk', value: 'user_fk' }} onChange={(val) => setFieldValue('coach_fk', val?.value || null)} />
            </FormInput>
            { has('spaces') && !hasLock('spaces') &&
                <FormInput containerProps={{ mb: '2rem' }} label={t('space')} width={'100%'}>
                    <AdvanceSelect isClearable type={SPACE_SELECT} options={spacesByLocation} initValue={values.spaces_id} onChange={(val) => setFieldValue('spaces_id', val?.id ?? null)}/>
            </FormInput>}
            <FormInput
                label={t('title')}
                value={values.place}
                placeholder={t('blocked-time-title-placeholder')}
                onChangeCallback={handleChange('place')}
                width={'100%'}
                maxLength={25}
                showCount
                containerProps={{ mb: '2rem' }}
            />
            {locationOptions.length > 1 &&
                <FormInput containerProps={{ mb: '2rem' }} label={t('location')} width={'100%'} error={(touched.locations_box_fk && errors.locations_box_fk) ? errors.locations_box_fk : null}>
                    <AdvanceSelect dontSort options={locationOptions} initValue={values.locations_box_fk} onChange={onLocationChange} isError={touched.locations_box_fk && errors.locations_box_fk}/>
                </FormInput>}
            <RepeatConfiguration {...props} allowSeries={!editType} hideSummary />

        </>
    );
};

export default BlockedTime;
