import React, { useState } from 'react';
import { Box, VStack } from "native-base";
import SimpleInput from './SimpleInput';
import AppText from './AppText';
import { isMobile } from '../../services/Helpers';
import { Colors } from '../../styles/Colors';
import { GlobalStyleAttributes } from '../../styles/GlobalStyles';
import { Collapse } from 'antd';
import InfoTooltip from "./InfoTooltip";
import { useMemo } from 'react';

/**
 * 
 * @param {width, containerProps, label, labelProps, error, errorProps, helper, helperProps, topEndElement, infoTooltip, children, collapsable, description} props 
 * @returns ReactElement
 */
const FormInput = (props) => {
    const { width, containerProps, label, labelProps, error, errorProps, helper, helperProps, topEndElement, infoTooltip, children, collapsable, description, errorHeight, required , validationSchema, name, ...rest } = props;
    const labelProp = labelProps || {};
    const errorProp = errorProps || {};
    const containerProp = containerProps || {};
    const helperProp = helperProps || {};
    const formWidth = width || (isMobile ? '100%' : '50%');
    const labelFlex = infoTooltip ? {style: {display: 'flex', alignItems: 'center'}} : {}
    const [collapseOpen, setCollapseOpen] = useState(false);
    const isRequired = useMemo(() => required ||  (validationSchema && name && validationSchema.fields[name]?.spec?.presence === 'required'), [required, validationSchema])

    if (collapsable) {
        return (
        <Box mb={'1rem'} space={'0.2rem'} {...containerProp} width={'100%'}>
            <Collapse
            className={'form-input-collapse'}
            onChange={(val) => setCollapseOpen(!!val?.length)}
            expandIconPosition={'end'}
                items={[{ key: '1', 
                label: <VStack>
                    {topEndElement && <Box h={0} alignSelf={'end'}>{topEndElement}</Box>}
                    { label && <AppText boldFont width='max-content' {...labelProp} {...labelFlex}>
                    {`${label}${isRequired ? '*' : ''}`}
                    {infoTooltip && <InfoTooltip title={infoTooltip}/>}
                    </AppText>}
                    {description && !collapseOpen && <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.newDesign.darkGrey}>
                        {description}
                    </AppText>}
                </VStack>, 
                children: <VStack space={'0.2rem'} width={formWidth} {...containerProp} mb={'0'}>
                    {children || <SimpleInput status={error ? 'error' : null} {...rest} />}
                    <VStack height={0}>
                        {helper && !error && <AppText color={Colors.newDesign.darkGrey} fontSize={GlobalStyleAttributes.fonts.infoText} {...helperProp}>
                            {helper}
                        </AppText>}
                        {error && <AppText color={Colors.error} fontSize={GlobalStyleAttributes.fonts.infoText} width='max-content' mediumFont {...errorProp}>
                            {error}
                        </AppText>}
                    </VStack>
                </VStack>
            }]}
            />
        </Box>
        );
    }

    return (
        <VStack mb={'1rem'} space={'0.2rem'} width={formWidth} {...containerProp}>
            {topEndElement && <Box mb={'-0.3rem'} h={0} alignSelf={'end'}>{topEndElement}</Box>}
            { label &&
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont width='max-content' {...labelProp} {...labelFlex}>
                    {`${label}${isRequired ? '*' : ''}`}
                    {infoTooltip && <InfoTooltip title={infoTooltip}/>}
                </AppText>}
            {children || <SimpleInput status={error ? 'error' : null} {...rest} />}
            <VStack height={errorHeight ?? 0}>
                {helper && !error &&
                    <AppText color={Colors.newDesign.darkGrey} fontSize={GlobalStyleAttributes.fonts.infoText} {...helperProp}>
                        {helper}
                    </AppText>}
                {error &&
                    <AppText color={Colors.error} fontSize={GlobalStyleAttributes.fonts.infoText} width='max-content' mediumFont {...errorProp}>
                        {error}
                    </AppText>}
            </VStack>
        </VStack>
    );
};

export default FormInput;
