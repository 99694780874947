import React, {memo, useEffect, useMemo, useState} from 'react';
import {HStack, VStack, Text as NBText} from "native-base";
import {Colors} from "../../../../../../styles/Colors";
import {t} from "../../../../../../services/i18n";
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../styles/GlobalStyles";
import {fetchApi} from "../../../../../../services/HTTP";
import {useDispatch, useSelector} from "react-redux";
import {getCategoriesByTypes} from "../../../../../../redux/ScheduleSlice";
import SimpleInput from "../../../../../common/SimpleInput";
import {ActivityIndicator} from "react-native";
import {optionalSteps} from "../StepOptions";
import moment from "moment";
import {dbTimeFormat, sendDataEvent} from "../../../../../../services/Helpers";
import AdvanceSelect from "../../../../../common/AdvanceSelect";
import {CATEGORY_SELECT, COACH_SELECT, CUSTOM_FIELD_SELECT, SPACE_SELECT} from "../../../../../../services/ConstHelper";
import LiveLinkSetting from "../ReusableComponents/LiveLinkSetting";
import {flowOptions} from "../FlowOptions";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import usePermissions from "../../../../../../services/Permissions";
import InfoTooltip from "../../../../../common/InfoTooltip";
import {store} from "../../../../../../redux/store";
import useCheckPermissions from '../../../../../../Hooks/useCheckPermission';

const BasicInformation = (props) => {
    const { values, setFieldValue, errors, touched,
        showLiveLinkSetting, fromAvailability, target, disableLocation, disableService, disableCoach,
        serviceChangeAlert, showMaxUsersTooltip, showSpaces, disableSpace, showCoach} = props;
    const services = useSelector(state => state.schedule.eventTypes.data)
    const box = useSelector(state => state.box.box)
    const selectedLocation = useSelector((state) => state.userSettings.scheduleLocation);
    const selectionsTree = useSelector((state) => state.stepper.selectionsTree);
    const flowName = useSelector(state => state.stepper.name)
    const editType = useSelector(state => state.stepper.editType)
    const categoryTypes = useSelector((state) => state.schedule.categoryTypes);
    const [allCustomFields, setAllCustomFields] = useState(null);
    const [coaches, setCoaches] = useState(null);
    const [secondCoaches, setSecondCoaches] = useState(null);
    const [availabilityServices, setAvailabilityServices] = useState(null);
    const [showServiceChangeAlert, setShowServiceChangeAlert] = useState(false);
    const [sessionTypeId, setSessionTypeId] = useState(null);
    const [maxUsersDefault, setMaxUsersDefault] = useState(null);
    const [liveLinkDefault, setLiveLinkDefault] = useState(null);
    const [spacesByLocation, setSpacesByLocation] = useState(null);
    const dispatch = useDispatch()
    const { has } = usePermissions()
    const { hasLimitedCoachViewSchedule } = useCheckPermissions()

    const sessionTypes = useMemo(() => {
        if(services && selectionsTree && selectionsTree[optionalSteps.EVENT_TYPE]){
            const type = categoryTypes.find(t => t.name === selectionsTree[optionalSteps.EVENT_TYPE])
            setSessionTypeId(type.id)
            sendDataEvent(`add ${type.name} pop up - Details`);
            return services[selectionsTree[optionalSteps.EVENT_TYPE]]
                .filter(item => !!item.active)
                .reduce((acc, item) => [...acc, {...item, value: item.id, label: item.name}], [])
        } else if(services && (flowName === flowOptions.APPOINTMENT_WITH_AVAILABILITY || flowName === flowOptions.APPOINTMENT_WITH_SPACE_AVAILABILITY) && availabilityServices) {
            const type = categoryTypes.find(t => t.name === 'appointment')
            sendDataEvent(`add ${type.name} pop up - Details`);
            setSessionTypeId(type.id)
            return availabilityServices
                .filter(item => !!item.active)
                .reduce((acc, item) => [...acc, {...item, value: item.id, label: item.name}], [])
        }
    },[services, selectionsTree, availabilityServices])
    const locations = useMemo(() => box ? box.locations_box.reduce((acc, location) => [...acc, {...location, label: location.location, value: location.id}],[]) : null, [box])

    useEffect(() => {
        fetchCoaches()
        if(!services) dispatch(getCategoriesByTypes(true))
        fetchAvailabilityServices();
    }, []);

    useEffect(() => {
        if(!allCustomFields) setAllCustomFields(values.customFields);
    }, [values.customFields]);

    useEffect(() => {
        if(sessionTypes && selectionsTree[optionalSteps.EVENT_TYPE] && services[selectionsTree[optionalSteps.EVENT_TYPE]].length === 1)
            setFieldValue('box_category_fk', services[selectionsTree[optionalSteps.EVENT_TYPE]][0].id)
    }, [sessionTypes]);

    useEffect(() => {
        if(box) {
            if(box.locations_box.length === 1) {
                setFieldValue('locations_box_fk', box.locations_box[0].id)
            }
        }
    }, [box]);

    useEffect(() => {
        if(selectedLocation && Object.keys(values).length && !values.locations_box_fk) {
            setValue('locations_box_fk', selectedLocation.id)
        }
    }, [selectedLocation, values.locations_box_fk]);

    useEffect(() => {
        let showServiceAlert = false
        if(sessionTypes && values.box_category_fk && !editType) {
            const service = sessionTypes.find(type => type.id === values.box_category_fk)
            if(service) {
                if('max_users' in values) {
                    setMaxUsersDefault(service.default_participants)
                    setValue('max_users', service.default_participants)
                }
                if ('end_time' in values) {
                    const end = moment(values.time, 'HH:mm').add(service.length, 'm').format(dbTimeFormat);
                    setValue('end_time', end)
                }
                if('multiDayConfig' in values) {
                    values.multiDayConfig?.days.map((day,i) => {
                        const start = moment(day.start_time, 'HH:mm')
                        if(service.length) setFieldValue(`multiDayConfig.days[${i}].end_time`, start.add(service.length, 'm').format(dbTimeFormat))
                    })
                }
            }
        }
        if(sessionTypes && values.box_category_fk && (values.box_category_fk !== target?.box_category_fk) && editType && serviceChangeAlert) {
            const newService = sessionTypes.find(type => type.id === values.box_category_fk)
            if(target?.box_categories?.membership_types?.length && newService.membership_types.length) {
                if(target?.box_categories?.membership_types?.[0]?.price !== newService.membership_types[0].price) {
                    showServiceAlert = true
                }
            }
        }
        setShowServiceChangeAlert(showServiceAlert)
    }, [values.box_category_fk, sessionTypes]);


    useEffect(() => {
        if(coaches && values.coach_fk) {
            if(coaches.length > 1) { // open second coach option
                setSecondCoaches(coaches.filter(coach => coach.id !== values.coach_fk))
            }
        } else {
            setSecondCoaches(null)
        }
    }, [values.coach_fk, coaches]);

    useEffect(() => {
        if(secondCoaches && values.second_coach_fk) {
            if(values.second_coach_fk === values.coach_fk) {
                setValue('second_coach_fk', null)
            }
            if (!secondCoaches.find(secondCoach => secondCoach.id === values.second_coach_fk)) {
                setValue('second_coach_fk', null)
            }
        }
    }, [values.second_coach_fk, secondCoaches]);

    useEffect(() => {
        if(Object.keys(values).length) {
            setSpacesByLocation(handleSpaceOnLocationChanged())
        }
    }, [values.locations_box_fk]);

    useEffect(() => {
        handleCustomFieldsFilterOnLocationChange();
    }, [values.locations_box_fk, allCustomFields]);

    useEffect(() => {
        setMaxUsersDefault(target?.max_users)
        setLiveLinkDefault(target?.live_link)
    }, [target]);

    const fetchCoaches = async () => {
        if(showCoach) {
            let coachesRes = await fetchApi('getCoachesOfBox', 'get', null, false, true);
            coachesRes.forEach(coach => {
                coach.ub_id = coach.id
                coach.id = coach.user_fk
                coach.value = coach.user_fk
                coach.label = `${coach.first_name} ${coach.last_name}`
                return coach
            })
            setCoaches(coachesRes);
            if (coachesRes.length === 1) {
                setFieldValue('coach_fk', coachesRes[0].id)
            }
        } else {
            setCoaches([]);
        }
    }

    const fetchAvailabilityServices = async () => {
        if((target?.id && fromAvailability) || target?.availability_id) {
            const prop = target.availability_id ? 'availability_id' : 'id'
            let servicesRes = await fetchApi(`availability/getAvailabilityCategories/${target[prop]}`, 'get', null, false, true);
            setAvailabilityServices(servicesRes)
            if(servicesRes.length === 1) {
                setFieldValue('box_category_fk', servicesRes[0].id)
            }
        }

    }

    const setValue = (name, value, shouldParseInt = false) => {
        setFieldValue(name, shouldParseInt ? (isNaN(parseInt(value)) ? '' : parseInt(value)) : value)
    }

    const onOnlineLinkRadioSelection = (selection) => {
        if(selection === 'in-person') setFieldValue('live_link', null)
        else setFieldValue('live_link', '')
    }

    const handleSpaceOnLocationChanged = () => {
        if(values.locations_box_fk) {
            const spaces = box.locations_box.find(location => location.id === values.locations_box_fk)?.spaces.reduce((acc, space) => [...acc, {...space, label: space.name, value: space.id}],[])
            const space = spaces.find(space => space.id === values.spaces_id)
            setValue('spaces_id', space ? space.id : null)
            return spaces;
        } else {
            return null
        }
    }

    const updateOrAddCustomField = (field, value, index) => {
        setValue(`customFields[${index}]`, {...field, value: value ?? null});
    }

    const handleCustomFieldsFilterOnLocationChange = () => {
        if(allCustomFields && values.locations_box_fk) {
            const filteredCustomFieldLocation = allCustomFields.filter(field => (
                field.value || field.value === null || (field.locations_box && field.locations_box.some(location => location.id === values.locations_box_fk))
            ));
            setValue('customFields',filteredCustomFieldLocation);
        }
    }

    return (
        <VStack space={'1rem'}>
            {coaches && sessionTypes && locations ?
                <>
                    <VStack space={'0.2rem'}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t(`${sessionTypes[0]?.category_type?.name ?? 'session'}-type-label`)}</AppText>
                        <AdvanceSelect options={sessionTypes} initValue={values.box_category_fk} type={CATEGORY_SELECT} categoryTypeId={sessionTypeId} hideCreateBtn={fromAvailability} onChange={(val) => setValue('box_category_fk', val?.id ?? null)} isDisabled={disableService} isClearable={true} isError={errors.box_category_fk && touched.box_category_fk}/>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.box_category_fk && touched.box_category_fk ? errors.box_category_fk : ''}</AppText>
                        {showServiceChangeAlert &&
                        <NBText style={{direction: 'inherit'}}>
                            <HStack alignItems={'center'} space={'0.2rem'}>
                                <FontAwesomeIcon color={Colors.error} size={'1rem'} icon={solid('circle-info')}/>
                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16} color={Colors.error} mediumFont>{t('we-dont-recommend')}</AppText>
                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16} color={Colors.error}>{t('changing-service-warning')}</AppText>
                            </HStack>
                        </NBText>
                        }
                    </VStack>
                    {showCoach &&
                    <VStack space={'0.2rem'}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('coach')}</AppText>
                        <AdvanceSelect options={coaches}
                                       initValue={values.coach_fk}
                                       type={COACH_SELECT}
                                       target={{name: 'coach_fk', value: 'user_fk'}}
                                       onChange={(val) => setValue('coach_fk', val?.value ?? null)}
                                       isDisabled={(fromAvailability && values.coach_fk) || disableCoach || hasLimitedCoachViewSchedule}
                                       isClearable={true}
                                       isError={errors.coach_fk && touched.coach_fk}
                        />
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.coach_fk && touched.coach_fk ? errors.coach_fk : ''}</AppText>
                    </VStack>
                    }
                    {secondCoaches && ('second_coach_fk' in values) &&
                        <VStack space={'0.2rem'}>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('second_coach')}</AppText>
                            <AdvanceSelect isDisabled={hasLimitedCoachViewSchedule} options={secondCoaches} initValue={values.second_coach_fk} type={COACH_SELECT} target={{name: 'second_coach_fk', value: 'user_fk'}} onChange={(val) => setValue('second_coach_fk', val?.id ?? null)} isClearable={true} isError={!!errors.second_coach_fk}/>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.second_coach_fk}</AppText>
                        </VStack>
                    }
                    { locations.length > 1 &&
                        <VStack space={'0.2rem'}>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('location')}</AppText>
                            <AdvanceSelect options={locations} initValue={values.locations_box_fk} onChange={(val) => setValue('locations_box_fk', val?.id ?? null)} isDisabled={disableLocation} isError={!!errors.locations_box_fk}/>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.locations_box_fk}</AppText>
                        </VStack>
                    }
                    { has('spaces') && showSpaces && spacesByLocation &&
                    <VStack space={'0.2rem'}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('space')}</AppText>
                        <AdvanceSelect type={SPACE_SELECT} options={spacesByLocation} initValue={values.spaces_id} onChange={(val) => setValue('spaces_id', val?.id ?? null)} isDisabled={disableSpace} isClearable={true} isError={!!errors.spaces_id}/>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.spaces_id}</AppText>
                    </VStack>
                    }
                    {
                        values.customFields?.sort((a, b) => a.id - b.id).map((field, index) => {
                            return  <VStack space={'0.2rem'}>
                                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t(field.custom_field_name)}</AppText>
                                        <AdvanceSelect dontSort target={field} initValue={field.value} type={CUSTOM_FIELD_SELECT} options={field.props?.options} onChange={(val) => updateOrAddCustomField(field, val?.value, index)} isClearable={!field.props?.isRequired} isError={!!(touched?.customFields && errors?.customFields?.length > 0 && errors?.customFields[index])}/>
                                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{touched.customFields && errors.customFields && errors.customFields[index] ? errors.customFields[index].value : null}</AppText>
                                    </VStack>
                        })
                    }
                    { 'max_users' in values &&
                        <VStack space={'0.2rem'}>
                            <HStack space={'1rem'}>
                                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('participant-number')}</AppText>
                                {showMaxUsersTooltip && <InfoTooltip title={t('participant-number-tooltip')}/>}
                            </HStack>
                            <HStack space={'1rem'} alignItems={'center'}>
                                <SimpleInput value={values.max_users}
                                             initValue={maxUsersDefault}
                                             max={9999}
                                             onChangeCallback={(val) => setValue('max_users', val, true)}
                                             width={'4rem'}
                                             numericOnly={true}
                                             status={errors.max_users && touched.max_users ? 'error' : null}
                                />
                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('participant-number-explain')}</AppText>
                            </HStack>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.max_users && touched.max_users ? errors.max_users : ''}</AppText>
                        </VStack>
                    }
                    {'live_link' in values && showLiveLinkSetting &&
                    <VStack space={'0.5rem'}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t(`online-${selectionsTree[optionalSteps.EVENT_TYPE] ?? 'session'}`)}</AppText>
                        <LiveLinkSetting selectionValue={values.live_link === null ? 'in-person' : 'online'}
                                         inputValue={values.live_link}
                                         initValue={liveLinkDefault}
                                         onChangeSelection={onOnlineLinkRadioSelection}
                                         onChangeText={(val) => setValue('live_link', val)}
                        />
                    </VStack>
                    }
                </>
                :
                <ActivityIndicator/>
            }
        </VStack>
    );
};

export default memo(BasicInformation);
