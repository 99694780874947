import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Spin} from "antd";
import {setError, setHideFooter, updateCurrStep} from "../../../../../../redux/stepperSlice";
import {t} from "../../../../../../services/i18n";
import {getMessageToUserFromError} from "../../../../../../services/Helpers";
import {useToast} from "react-native-toast-notifications";

const Payment = (props) => {
    const { values, setFieldValue, handleSubmit, cartOverride } = props;
    const user = useSelector((state) => state.stepper.props?.user);
    const error = useSelector((state) => state.stepper.error);
    const box = useSelector(state => state.box.box)
    const lang = useSelector(state => state.userSettings.lang);
    const token = useSelector(state => state.auth?.data?.token);
    const flowConfig = useSelector(state => state.stepper)
    const [submitted, setSubmitted] = useState(false);
    const [iframeSrc, setIframeSrc] = useState(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch()
    const toast = useToast();

    const boxLang = useMemo(() => lang?.language === 'Hebrew' ? 'he' : 'en', [lang])
    const cart = useMemo(() => cartOverride ?? values.cart ?? [], [cartOverride, values.cart])
    const locationId = useMemo(() => cart.length ? cart[0].location_box_fk : null, [cart])
    const hideBack = useMemo(() => flowConfig.steps.length === 1, [flowConfig.steps])

    useEffect(() => {
        dispatch(setHideFooter(true))
        listenToMessages()
        return () => {
            window.removeEventListener("message", listenToMessages);
            setSubmitted(false)
            dispatch(setHideFooter(false))
        };
    }, []);

    useEffect(() => {
        if(locationId && boxLang && cart.length) {
            const iframe = getIframeSrc()
            setIframeSrc(iframe)
        }
    }, [locationId, boxLang, user, cart]);

    useEffect(() => {
        setFieldValue('user', user ?? null)
    }, [user]);

    useEffect(() => {
        if(values.payment_info && !submitted) {
            handleSubmit()
            setSubmitted(true)
        }
    }, [values.payment_info, submitted]);

    useEffect(() => {
        if(error && error !== '') {
            setFieldValue('payment_info', null)
            setIframeSrc(null)
            setSubmitted(false)
            console.log('error',error)
            const errorMessage = getMessageToUserFromError(error)
            toast.show(errorMessage !== '' ? errorMessage : t('payment-error'), { type: 'danger', duration: 5000 });
            setTimeout(() => setIframeSrc(getIframeSrc()), 10)
            dispatch(setError(null))
        }
    }, [error]);

    const listenToMessages = () => {
        window.addEventListener('message', (event) => {
            if(event.data.type === "PAYMENT_RESPONSE_SUCCESS") {
                console.log('listening.. ', event.data.data)
                setFieldValue('payment_info', event.data.data)
            }
            if(event.data.type === "PAYMENT_MANAGE_BACK") {
                const stepIndex = flowConfig.steps.indexOf(flowConfig?.currStep);
                dispatch(updateCurrStep(flowConfig.steps[stepIndex - 1]))
            }
            if(event.data.type === "PAYMENT_RESPONSE_ERROR") {
                console.log('error!!!! ', event.data)
                dispatch(setError({messageToUser: t('payment-generic-error'), message: 'payment-error'}))
            }
        });
    }

    // name and phone are only for meshulam
    const getUserName = () => {
        const firstName = user?.first_name ?? values?.first_name
        const lastName = user?.last_name ?? values?.last_name
        if(firstName && lastName) {
            if(firstName.length >= 2 && lastName.length >= 2) {
                return `&name=${firstName} ${lastName}`
            }
        }

        return ''
    }

    const getUserPhone = () => {
        const phone = user?.phone ?? values?.phone
        if(phone && (phone[0] === '0' || phone.startsWith('972')) && phone.length >= 10) {
            return `&phone=${phone}`
        }
        return ''
    }

    const getStartDates = () => {
        if(cart.some(item => item.start)) {
            const cartWithDates = cart.reduce((acc, item) => (item.start ? {[item.id]: item.start} : acc), {})
            return Object.keys(cartWithDates).length ? `&startDates=${JSON.stringify(cartWithDates)}` : ''
        }
        return ''
    }

    const getIframeSrc = () => {
        return `${process.env.REACT_APP_PAYMENTS_URL}?box_id=${box.id}&lang=${boxLang}&locations_box_id=${locationId}&userId=${user?.user_fk ?? null}&cart=${JSON.stringify(cart.map(item => item.id))}&staffToken=${token}&hideBack=${hideBack}${getUserName()}${getUserPhone()}${getStartDates()}`
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
            <Spin spinning={loading} style={{alignSelf: 'center', justifyContent:'center', flex: 1}}/>
            {iframeSrc &&
                <iframe frameBorder={0}
                        style={{width: '100%', flex: 1}}
                        onLoad={() => setLoading(false)}
                        src={iframeSrc}/>}
        </div>
    );
};

export default Payment;