import React, {useEffect, useMemo, useState} from 'react';
import {HStack, Stack, VStack, Text as NBText} from "native-base";
import SharedTimepicker from "../../../../../common/SharedTimepicker";
import moment from "moment";
import {
    dbDateFormat,
    dbTimeFormat,
    getHourAndMinuteDiff,
    isBoxIL,
    isMobile, sendDataEvent,
    sortDays
} from "../../../../../../services/Helpers";
import {GlobalStyleAttributes, WebGlobalStyles} from "../../../../../../styles/GlobalStyles";
import AppText from "../../../../../common/AppText";
import {t} from "../../../../../../services/i18n";
import {Colors} from "../../../../../../styles/Colors";
import {useDispatch, useSelector} from "react-redux";
import {fetchApi} from "../../../../../../services/HTTP";
import {getCategoriesByTypes} from "../../../../../../redux/ScheduleSlice";
import {ActivityIndicator} from "react-native";
import {optionalSteps} from "../StepOptions";
import {updateSummary} from "../../../../../../redux/stepperSlice";
import AdvanceSelect from "../../../../../common/AdvanceSelect";
import {COACH_SELECT, SPACE_SELECT} from "../../../../../../services/ConstHelper";
import {generateSeriesName} from "../../../../../../services/ScheduleService";
import SimpleInput from "../../../../../common/SimpleInput";
import {isString} from "formik";
import {flowOptions} from "../FlowOptions";
import CustomCheckbox from "../../../../../common/CustomCheckbox";
import {Radio} from "antd";
import InfoTooltip from "../../../../../common/InfoTooltip";
import MultiSingleMenuWithSearch from "../../../../../common/MultiSingleMenuWithSearch";
import CustomButtonV2 from "../../../../../common/CustomButtonV2";
import useCheckPermissions from '../../../../../../Hooks/useCheckPermission';

const MultiDayConfiguration = (props) => {
    const { values, errors, setFieldValue, touched, showLocationSelect, showCoachSelect, showSpacesSelect, spaceName, servicesTypeOverride, showUnlimitedOption, showShortenName } = props;
    const box = useSelector((state) => state.box.box);
    const flowName = useSelector((state) => state.stepper.name);
    const selectedLocation = useSelector((state) => state.userSettings.scheduleLocation);
    const services = useSelector(state => state.schedule.eventTypes.data)
    const selectionsTree = useSelector((state) => state.stepper.selectionsTree);
    const lang = useSelector((state) => state.userSettings.lang);
    const [coaches, setCoaches] = useState(null);
    const [selectedCoachName, setSelectedCoachName] = useState(null);
    const [selectedSpaceName, setSelectedSpaceName] = useState(spaceName ?? null);
    const [boxLocale] = useState(lang?.language === 'Hebrew' ? 'he' : 'en');
    const { hasLimitedCoachViewSchedule } = useCheckPermissions();

    const mobile = isMobile
    const days = useMemo(() => ({
        0: {name: t(mobile ? 'sundayShortened' : 'sunday'), id: 0},
        1: {name: t(mobile ? 'mondayShortened' : 'monday'), id: 1},
        2: {name: t(mobile ? 'tuesdayShortened' : 'tuesday'), id: 2},
        3: {name: t(mobile ? 'wednesdayShortened' : 'wednesday'), id: 3},
        4: {name: t(mobile ? 'thursdayShortened' : 'thursday'), id: 4},
        5: {name: t(mobile ? 'fridayShortened' : 'friday'), id: 5},
        6: {name: t(mobile ? 'saturdayShortened' : 'saturday'), id: 6}
    }), [])
    const daysWithOrder = useMemo(() => {
        if(days) {
            if (isBoxIL()) return Object.values(days);
            else return [days['1'], days['2'], days['3'], days['4'], days['5'], days['6'], days['0']]
        }
    }, [days]);
    const defaultEndTime = useMemo(() => (values.multiDayConfig && 'days' in values.multiDayConfig && values.multiDayConfig?.days.length > 0) ? values.multiDayConfig?.days[0].end_time : null,[values.multiDayConfig])
    const timeFormat = useMemo(() => box ? box.locations_box[0].time_format : null,[box])
    const dateFormat = useMemo(() => box ? box.locations_box[0].date_format : null,[box])
    const locations = useMemo(() => box ? box.locations_box.reduce((acc, location) => [...acc, {...location, label: location.location, value: location.id}],[]) : null, [box])
    const spaces = useMemo(() => (selectedLocation?.spaces || []).reduce((acc, space) => [...acc, {...space, label: space.name, value: space.id}], []),[selectedLocation])
    const sessionTypes = useMemo(() => {
        if(services) {
            if(selectionsTree && selectionsTree[optionalSteps.EVENT_TYPE]) return services[selectionsTree[optionalSteps.EVENT_TYPE]].filter(service => !!service.active)
            else if(servicesTypeOverride) return services[servicesTypeOverride].filter(service => !!service.active)
        }
        return null
    },[services, selectionsTree])
    const dispatch = useDispatch()

    useEffect(() => {
        sendDataEvent('workshop or availability step');
    }, []);

    useEffect(() => {
        if('coach_ub_id' in values && !coaches) fetchCoaches()
        if(!services) dispatch(getCategoriesByTypes(true))
    }, [values]);

    useEffect(() => {
        if(values?.multiDayConfig?.days && 'seriesName' in values?.multiDayConfig) {
            callGenerateSeriesName()
        }
    }, [values?.multiDayConfig?.days, values?.end_date, values?.start_date]);

    useEffect(() => {
        if(box) {
            if(box.locations_box.length === 1 && 'location_fk' in values) {
                setFieldValue('location_fk', box.locations_box[0].id)
            }
        }
    }, [box]);

    useEffect(() => {
        if(coaches && values.coach_ub_id) {
            const coach = coaches.find(coach => coach.id === values.coach_ub_id)
            setSelectedCoachName(coach.name)
        }
    }, [values.coach_ub_id, coaches]);

    useEffect(() => {
        if(spaces && values.spaces_id) {
            const space = spaces.find(space => space.id === values.spaces_id)
            setSelectedSpaceName(space.name)
        }
    }, [values.spaces_id, spaces]);

    useEffect(() => {
        if(selectedLocation && Object.keys(values).length && !values.location_fk) {
            setValue('location_fk', selectedLocation.id)
        }
    }, [selectedLocation, values.location_fk]);

    useEffect(() => {
        if('spaces_id' in values && spaces && spaces.length === 1) {
            setFieldValue('spaces_id', spaces[0].id)
        }
    }, [spaces]);

    const onDateChange = (name, value) => {
        const date = moment(value).format(dbDateFormat)
        setFieldValue(name, date)
    }

    const toggleDay = (isOpen, dayId) => {
        //the checkbox wrapper with pressable is a workaround because checkbox onchange is buggy without checkbox-group
        let newDays = [...values.multiDayConfig.days.filter(d => d.day !== dayId)]
        if(isOpen) {
            newDays = [...newDays, {day: dayId, start_time: '07:00:00', end_time: defaultEndTime ?? '17:00:00'}]
        }
        setFieldValue('multiDayConfig.days', newDays)
    }

    const toggleDayFromArray = (isOpen, dayId) => {
        let newDays = [...values.multiDayConfig.filter(d => d.day !== dayId)]
        if(isOpen) {
            newDays = [...newDays, {day: dayId, setting: [{start_time: '07:00:00', end_time: '17:00:00', limitations: []}]}]
        }
        setFieldValue('multiDayConfig', newDays)
    }

    const onTimeChange = (name, value) => {
        const time = value.m.format(dbTimeFormat)
        setFieldValue(name, time)
    }

    const callGenerateSeriesName = () => {
        const boxCategoryName = sessionTypes.find(type => type.id === values.box_category_fk).name
        const seriesName = generateSeriesName(values.multiDayConfig.days, values.start_date, values.end_date, dateFormat, timeFormat, showShortenName, t, boxCategoryName)
        setFieldValue('multiDayConfig.seriesName', seriesName)
    }

    const generateSummary = () => {
        const date = moment(values.start_date ,dbDateFormat).format(lang?.language == 'Hebrew' ? 'DD MMM, YYYY' : 'MMM DD, YYYY');
        let timeDict = {}
        const sortedDays = values.multiDayConfig?.days.sort((a, b) => sortDays(a.day, b.day))

        sortedDays.map(day => {
            const startTime = moment(day.start_time, dbTimeFormat).format(timeFormat)
            const endTime = moment(day.end_time, dbTimeFormat).format(timeFormat)
            const hourRangeText = `${t('fromTime')} ${startTime} ${t('toTime')} ${endTime}`
            timeDict[hourRangeText] = timeDict[hourRangeText] ? [...timeDict[hourRangeText], day.day] : [day.day]
        })
        return getSummaryFromHourDictionary(timeDict, date)
    }

    const generateSummaryFromArray = () => {
        const date = moment(values.date,dbDateFormat).format(lang?.language == 'Hebrew' ? 'DD MMM, YYYY' : 'MMM DD, YYYY');
        let timeDict = {}
        const sortedDays = values.multiDayConfig.sort((a, b) => sortDays(a.day, b.day))

        sortedDays.map(day => {
            day.setting.map(setting => {
                const startTime = moment(setting.start_time, dbTimeFormat).format(timeFormat)
                const endTime = moment(setting.end_time, dbTimeFormat).format(timeFormat)
                const hourRangeText = `${t('fromTime')} ${startTime} ${t('toTime')} ${endTime}`
                timeDict[hourRangeText] = timeDict[hourRangeText] ? [...timeDict[hourRangeText], day.day] : [day.day]
            })
        })

        return getSummaryFromHourDictionary(timeDict, date)
    }

    const getSummaryFromHourDictionary = (timeDict, date) => {
        let daysAndTimes = ''
        daysAndTimes = Object.keys(timeDict).reduce((acc, hours, i) => {
            const days = timeDict[hours].reduce((acc, day) => [...acc, t(`day-${day+1}`)],[])
            return `${acc} ${days.join(` ${t('and')} `)} ${hours}${i !== Object.keys(timeDict).length -1 ? ', ' : ''}`
        }, '');
        const startDate = values.start_date ? moment(values.start_date, dbDateFormat).locale(boxLocale).format(lang?.language == 'Hebrew' ? 'DD MMM, YYYY':'MMM DD, YYYY') : null;
        const endDate = values.end_date ? moment(values.end_date, dbDateFormat).locale(boxLocale).format(lang?.language == 'Hebrew' ? 'DD MMM, YYYY':'MMM DD, YYYY') : null;
        const summary = t(`${flowName}-summary`, {date: date, daysAndTimes: daysAndTimes, coach: selectedSpaceName ?? selectedCoachName ?? '', startDate, endDate})
        dispatch(updateSummary(summary))
        return `${t('summary-label')} ${summary}`
    }

    const fetchCoaches = async () => {
        let coachesRes = await fetchApi('getCoachesOfBox', 'get', null, false, true);
        coachesRes.forEach(coach => {
            coach.label = `${coach.first_name} ${coach.last_name}`
            coach.value = coach.id
            return coach
        })
        setCoaches(coachesRes);
        if(coachesRes.length === 1) {
            setFieldValue('coach_ub_id', coachesRes[0].id)
        }
    }

    const setValue = (name, value, shouldParseInt = false) => {
        console.log('setValue', name, value, typeof value)
        setFieldValue(name, shouldParseInt ? (isNaN(parseInt(value)) ? '' : parseInt(value)) : value)
    }

    const addSettingToDay = (index) => {
        let newSetting = [...values.multiDayConfig[index].setting]
        newSetting = [...newSetting, {start_time: '18:00:00', end_time: '20:00:00', limitations: []}]
        setFieldValue(`multiDayConfig[${index}].setting`, newSetting)
    }

    const removeSettingFromDay = (indexInArray, indexInSetting) => {
        let newSetting = [...values.multiDayConfig[indexInArray].setting]
        newSetting.splice(indexInSetting, 1)
        setFieldValue(`multiDayConfig[${indexInArray}].setting`, newSetting)
    }

    const handleLimitationChange = (limitations, indexInArray, indexInSetting) => {
        setFieldValue(`multiDayConfig[${indexInArray}].setting[${indexInSetting}].limitations`, limitations)
    }

    const onUnlimitedSelectChange = (selected) => {
        setFieldValue('isUnlimited', selected === 'unlimited')
        const end_date = values.start_date ? moment(values.start_date, dbDateFormat).add(1, 'month').format(dbDateFormat) : moment().add(1, 'month').format(dbDateFormat)
        setFieldValue('end_date', selected === 'unlimited' ? null : end_date)
    }

    return (
        <>
            {
                !('coach_ub_id' in values) || ('coach_ub_id' in values && coaches)
                ?
                <VStack space={'1.2rem'}>
                    { showCoachSelect && coaches &&
                        <VStack space={'0.2rem'}>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('coach')}</AppText>
                            <AdvanceSelect options={coaches} initValue={values.coach_ub_id} type={COACH_SELECT} target={{name: 'coach_ub_id', value: 'id'}} onChange={(val) => setValue('coach_ub_id', val?.id)} isClearable={true} isError={errors.coach_ub_id && touched.coach_ub_id} isDisabled={hasLimitedCoachViewSchedule}/>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.coach_ub_id && touched.coach_ub_id ? errors.coach_ub_id : ''}</AppText>
                        </VStack>
                    }
                    { showLocationSelect && locations.length > 1 && 'location_fk' in values &&
                        <VStack space={'0.2rem'}>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('location')}</AppText>
                            <AdvanceSelect options={locations} initValue={values.location_fk} onChange={(val) => setValue('location_fk', val?.id)} isError={!!errors.location_fk}/>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.location_fk}</AppText>
                        </VStack>
                    }
                    { showSpacesSelect && spaces &&
                        <VStack space={'0.2rem'}>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('space')}</AppText>
                            <AdvanceSelect type={SPACE_SELECT} options={spaces} initValue={values.spaces_id} onChange={(val) => setValue('spaces_id', val?.id)} isClearable={true} isError={errors.spaces_id && touched.spaces_id}/>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.spaces_id && touched.spaces_id ? errors.spaces_id : ''}</AppText>
                        </VStack>
                    }
                    <VStack space={'0.2rem'}>
                        <HStack space={'0.5rem'} width={('end_date' in values) && !showUnlimitedOption ? '100%' : '50%'}>
                            <VStack space={'0.2rem'} flex={1}>
                                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('startDateLabel')}</AppText>
                                <SharedTimepicker type={'date'} value={values['start_date' in values ? 'start_date' : 'date'] ? moment(values['start_date' in values ? 'start_date' : 'date'], dbDateFormat) : moment()} onChange={(date) => onDateChange('start_date' in values ? 'start_date' : 'date', date)} textStyle={{fontSize: GlobalStyleAttributes.fonts.standard16}}/>
                            </VStack>
                            {'end_date' in values && !showUnlimitedOption &&
                                <VStack space={'0.2rem'} flex={1}>
                                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('endDateLabel')}</AppText>
                                    <SharedTimepicker type={'date'} value={moment(values.end_date, dbDateFormat)} onChange={(date) => onDateChange('end_date', date)} textStyle={{fontSize: GlobalStyleAttributes.fonts.standard16}}/>
                                </VStack>
                            }
                        </HStack>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.date}</AppText>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.start_date}</AppText>
                        {!showUnlimitedOption && <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.end_date}</AppText>}
                    </VStack>
                    {showUnlimitedOption &&
                    <VStack>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('ends')}</AppText>
                        <Radio.Group name={'end-date-radio'} onChange={(e) => onUnlimitedSelectChange(e.target.value)} value={values.isUnlimited ? 'unlimited' : 'until'}>
                            <VStack>
                                <HStack space={'1rem'} alignItems={'center'} h={'40px'}>
                                    <Radio value="until" size="sm"><AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('set-date')}</AppText></Radio>
                                    {!values.isUnlimited &&
                                    <SharedTimepicker type={'date'}
                                                      value={moment(values.end_date, dbDateFormat)}
                                                      onChange={(date) => onDateChange('end_date', date)}
                                                      textStyle={{fontSize: GlobalStyleAttributes.fonts.standard16}}
                                                      min={values.start_date ?? moment().format(dbDateFormat)}
                                                      max={values.start_date ? moment(values.start_date, dbDateFormat).add(2,'y').format(dbDateFormat) : moment().add(2, 'y').format(dbDateFormat)}
                                    />}
                                </HStack>
                                <HStack h={'40px'} alignItems={'center'}>
                                    <Radio value="unlimited" size="sm"><AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('never')}</AppText></Radio>
                                </HStack>
                            </VStack>
                        </Radio.Group>
                    </VStack>
                    }
                    {
                        'repeat' in values &&
                        <VStack space={'0.2rem'} flex={1}>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('repeat')}</AppText>
                            <Radio.Group name={'repeat-type-radio'} onChange={(e) => setValue('repeat', e.target.value === 'repeat')} value={values.repeat ? 'repeat' : 'one-time'}>
                                <HStack space={'1.5rem'}>
                                    <Radio value="repeat" size="sm"><AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('on-going')}</AppText></Radio>
                                    <Radio value="one-time" size="sm"><AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('oneTime')}</AppText></Radio>
                                </HStack>
                            </Radio.Group>
                        </VStack>
                    }
                    {
                        'slot_length' in values &&
                        <VStack space={'0.2rem'} flex={1}>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('increments')}</AppText>
                            <NBText style={{direction: 'inherit'}}>
                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('time-slot-half-1')}</AppText>
                                <SimpleInput value={values.slot_length}
                                             onChangeCallback={(val) => setValue('slot_length', val, true)}
                                             numericOnly={true}
                                             max={999}
                                             width={'4rem'}
                                             styleOverride={{margin: '0 0.5rem'}}
                                             status={errors.slot_length ? 'error' : null}
                                />
                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('time-slot-half-2')}</AppText>
                            </NBText>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.slot_length}</AppText>
                        </VStack>
                    }
                    {
                        'break_length' in values &&
                        <VStack space={'0.2rem'} flex={1}>
                            <HStack>
                                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('break-length-header')}</AppText>
                                <InfoTooltip title={t('break-length-tooltip')}/>
                            </HStack>
                            <NBText style={{direction: 'inherit'}}>
                                <SimpleInput value={values.break_length}
                                             onChangeCallback={(val) => setValue('break_length', val, true)}
                                             numericOnly={true}
                                             max={999}
                                             width={'4rem'}
                                             status={errors.break_length ? 'error' : null}
                                />
                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16} style={{marginHorizontal: '0.5rem'}}>{t('break-length-explain')}</AppText>
                            </NBText>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.break_length}</AppText>
                        </VStack>
                    }
                    {
                        'block_registration_time' in values && ![flowOptions.WORKSHOP_FLOW, flowOptions.HUGIM_FLOW].includes(flowName) &&
                        <VStack space={'0.2rem'} flex={1}>
                            <HStack>
                                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('limitRegistrationTimeAvailability')}</AppText>
                                <InfoTooltip title={t('limitRegistrationTimeAvailabilityTooltip')}/>
                            </HStack>
                            <NBText style={{direction: 'inherit'}}>
                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('limit-registration-time-half-1')}</AppText>
                                <SimpleInput value={values.block_registration_time}
                                             onChangeCallback={(val) => setValue('block_registration_time', val, false)}
                                             inputType={'float'}
                                             width={'4rem'}
                                             styleOverride={{margin: '0 0.5rem'}}
                                             status={errors.block_registration_time ? 'error' : null}
                                />
                                <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{t('limit-registration-time-half-2')}</AppText>
                            </NBText>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.block_registration_time}</AppText>
                        </VStack>
                    }
                    <VStack space={'0.2rem'}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('schedule-on')}</AppText>
                            {values.multiDayConfig?.days &&
                                <VStack space={'0.2rem'}>
                                    {daysWithOrder.map(day => {
                                        const selectedDayIndex = values.multiDayConfig.days.findIndex(d => d.day === day.id)
                                        const isSelected = selectedDayIndex !== -1
                                        const diffObj = isSelected ? getHourAndMinuteDiff(values.multiDayConfig.days[selectedDayIndex].start_time, values.multiDayConfig.days[selectedDayIndex].end_time) : null
                                        return (
                                            <HStack space={'1rem'} key={`repeat-days-${day.id}`}
                                                    bg={isSelected ? Colors.newDesign.menuBar : Colors.newDesign.white}
                                                    height={'3rem'} alignItems={'center'} justifyContent={'space-between'}>
                                                <CustomCheckbox text={day.name} isChecked={isSelected} onChange={() => toggleDay(!isSelected, day.id)}/>
                                                {isSelected &&
                                                <HStack space={mobile ? '1rem' : '2rem'} alignItems={'center'}>
                                                    <HStack space={'0.5rem'} alignItems={'center'}>
                                                        <SharedTimepicker type={'time'}
                                                                          value={moment(values.multiDayConfig.days[selectedDayIndex].start_time, dbTimeFormat)}
                                                                          onChange={(time) => onTimeChange(`multiDayConfig.days[${selectedDayIndex}].start_time`, time)}
                                                                          inputStyle={[WebGlobalStyles.customInputNew, mobile && {padding: '5px'}, {width: mobile ? '4.5rem' : '5.5rem'}]}
                                                                          textStyle={{fontSize: GlobalStyleAttributes.fonts.standard16}}/>
                                                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText}>-</AppText>
                                                        <SharedTimepicker type={'time'}
                                                                          value={moment(values.multiDayConfig.days[selectedDayIndex].end_time, dbTimeFormat)}
                                                                          onChange={(time) => onTimeChange(`multiDayConfig.days[${selectedDayIndex}].end_time`, time)}
                                                                          textStyle={{fontSize: GlobalStyleAttributes.fonts.standard16}}
                                                                          inputStyle={[WebGlobalStyles.customInputNew, mobile && {padding: '5px'}, {width: mobile ? '4.5rem' : '5.5rem'}, (diffObj.hours + diffObj.minutes) <= 0 && {borderColor: Colors.error}]}
                                                        />
                                                    </HStack>
                                                </HStack>
                                                }
                                            </HStack>
                                    )})}
                                    {errors.multiDayConfig?.days && isString(errors.multiDayConfig.days) && <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.multiDayConfig.days}</AppText>}
                                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.newDesign.darkGrey}>{generateSummary()}</AppText>
                                </VStack>
                            }
                            {Array.isArray(values.multiDayConfig) &&
                                <VStack space={'0.2rem'}>
                                    {daysWithOrder.map(day => {
                                        const selectedDayIndex = values.multiDayConfig.findIndex(d => d.day === day.id)
                                        const isSelected = selectedDayIndex !== -1
                                        return (
                                            <HStack space={'1rem'} key={`repeat-days-${day.id}`} p={'0.7rem'} bg={isSelected ? Colors.newDesign.menuBar : Colors.newDesign.white} minHeight={'3rem'} alignItems={'center'}>
                                                <HStack space={'1rem'} flex={1} alignSelf={isSelected && values.multiDayConfig[selectedDayIndex].setting.length > 1 ? 'start' : 'center'}>
                                                    <CustomCheckbox text={day.name} isChecked={isSelected} onChange={() => toggleDayFromArray(!isSelected, day.id)}/>
                                                </HStack>
                                                <VStack space={'0.5rem'}>
                                                    {isSelected && values.multiDayConfig[selectedDayIndex].setting.map((item, index) => {
                                                        const diffObj = getHourAndMinuteDiff(item.start_time, item.end_time)
                                                        return (
                                                            <HStack space={'1rem'} alignItems={'center'} key={`repeat-days-setting-${day.id}-${index}`}>
                                                                <Stack direction={mobile ? 'column' : 'row'} space={'0.5rem'} alignItems={'center'}>
                                                                    <HStack space={'0.5rem'} alignItems={'center'} w={mobile ? '100%' : 'auto'}>
                                                                        <SharedTimepicker type={'time'}
                                                                                          value={moment(item.start_time, dbTimeFormat)}
                                                                                          onChange={(time) => onTimeChange(`multiDayConfig[${selectedDayIndex}].setting[${index}].start_time`, time)}
                                                                                          wrapperStyle={[mobile && {flex:1}]}
                                                                                          textStyle={{fontSize: GlobalStyleAttributes.fonts.standard16}}
                                                                                          inputStyle={[mobile ? {padding: '5px', width: '5rem'} : {width: '5.5rem'}]}/>
                                                                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText}>-</AppText>
                                                                        <SharedTimepicker type={'time'}
                                                                                          value={moment(item.end_time, dbTimeFormat)}
                                                                                          onChange={(time) => onTimeChange(`multiDayConfig[${selectedDayIndex}].setting[${index}].end_time`, time)}
                                                                                          wrapperStyle={[mobile && {flex:1}]}
                                                                                          textStyle={{fontSize: GlobalStyleAttributes.fonts.standard16}}
                                                                                          inputStyle={[mobile ? {padding: '5px', width: '5rem'} : {width: '5.5rem'}, (diffObj.hours + diffObj.minutes) <= 0 && {borderColor: Colors.error}]}/>
                                                                    </HStack>
                                                                    {sessionTypes && sessionTypes.length > 0 && <MultiSingleMenuWithSearch options={sessionTypes}
                                                                                                                                           onChange={(val) => handleLimitationChange(val, selectedDayIndex, index)}
                                                                                                                                           placeholder={mobile ? t('choose') : t('select-services')}
                                                                                                                                           width={mobile ? '100%' : '150px'}/>}
                                                                </Stack>
                                                                <CustomButtonV2 onClick={() => index === 0 ? addSettingToDay(selectedDayIndex) : removeSettingFromDay(selectedDayIndex, index)}
                                                                                type={'link'}
                                                                                iconName={index === 0 ? 'fa-plus' : 'fa-trash-can'}
                                                                                iconProps={index === 0 ? {iconColor: Colors.newDesign.mainBlue} : {}}
                                                                />
                                                            </HStack>
                                                        )
                                                    })}
                                                </VStack>
                                            </HStack>
                                        )})}
                                    {isString(errors.multiDayConfig) && <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{errors.multiDayConfig}</AppText>}
                                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.newDesign.darkGrey}>{generateSummaryFromArray()}</AppText>
                                </VStack>
                            }
                    </VStack>
                </VStack>
                :
                <ActivityIndicator/>
            }
        </>
    );
};

export default MultiDayConfiguration;
