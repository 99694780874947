import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    width={53}
    height={53}
    viewBox="0 0 53 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={26.5} cy={26.5} r={26} fill="#54A1F8" stroke="#3B3B3B" />
    <Path
      d="M41 13.186H13v29.093h28V13.186Z"
      fill="#fff"
      stroke="#3B3B3B"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <Path
      d="M41 13.186H13v5.6h28v-5.6Z"
      fill="#fff"
      stroke="#3B3B3B"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <Path
      d="M15.459 14.688a.408.408 0 0 1-.411-.409v-2.868a.411.411 0 0 1 .41-.411.409.409 0 0 1 .41.411v2.868a.407.407 0 0 1-.41.41ZM23.171 14.688a.407.407 0 0 1-.411-.409v-2.868a.41.41 0 0 1 .411-.411.411.411 0 0 1 .41.411v2.868a.41.41 0 0 1-.41.41ZM30.913 14.688a.407.407 0 0 1-.411-.409v-2.868a.41.41 0 0 1 .41-.411.411.411 0 0 1 .41.411v2.868a.41.41 0 0 1-.41.41ZM38.652 14.688a.406.406 0 0 1-.409-.409v-2.868a.407.407 0 0 1 .41-.411.41.41 0 0 1 .409.411v2.868a.405.405 0 0 1-.41.41Z"
      fill="#3B3B3B"
    />
  </Svg>
)

export default SvgComponent
