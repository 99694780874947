import * as React from "react"
import Svg, { Path } from "react-native-svg"
const SvgComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 640 512"
        {...props}
    >
        <Path d="M0 256C0 114.6 114.6 0 256 0c24.4 0 48 3.4 70.3 9.8-8.5 7.6-16.9 15.1-25.4 22.7-1.2 1.1-2.3 2.2-3.4 3.3-13.4-2.5-27.3-3.8-41.5-3.8C132.3 32 32 132.3 32 256s100.2 224 223.9 224c32.3 0 62.9-6.8 90.6-19.1 5.8 9.3 12.5 18.1 19.9 26.1-33.4 16-70.9 25-110.4 25C114.6 512 0 397.4 0 256zm96 0c0-88.4 71.6-160 160-160 6.3 0 12.5.4 18.6 1.1.1 2.7.4 5.4.9 8.1 1.4 8.3 2.8 16.7 4.2 25-7.7-1.4-15.6-2.2-23.7-2.2-70.7 0-128 57.3-128 128s57.3 128 128 128c23.3 0 45.2-6.2 64-17.1v1.9c.1 11.1 1.1 22 3.2 32.5C302.7 410.7 280 416 256 416c-88.4 0-160-71.6-160-160zm148.6-11.3 74.2-74.2c-3.9-23.5-7.8-46.9-11.7-70.4-2.7-16.3 3.2-32.8 15.5-43.8C339.1 41.7 355.5 27 372 12.4c13.1-11.7 33.9-5.8 38.9 11 6 19.9 12 39.9 18 59.8 19.9 6 39.8 11.9 59.7 17.9 16.8 5 22.7 25.8 11 38.9-14.6 16.5-29.3 32.9-43.9 49.4-4.5 5-9.8 9-15.7 11.7-2.6.9-5.2 1.8-7.8 2.8-6.5 1.7-13.4 2.1-20.3 1-23.5-3.9-46.9-7.8-70.4-11.7l-.1-.1-74.2 74.2c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6zm94-149.8c2.7 16 5.3 31.9 8 47.9l51.3-51.3c-4.8-15.6-9.4-31.2-14.1-46.8-13.3 11.9-26.7 23.7-40 35.6-4.2 3.7-6.1 9.2-5.2 14.6zM352 368c0-79.5 64.5-144 144-144 45.3 0 85.6 20.9 112 53.5V240c0-8.8 7.2-16 16-16s16 7.2 16 16v80c0 8.8-7.2 16-16 16h-80c-8.8 0-16-7.2-16-16s7.2-16 16-16h43.9c-20.2-29-53.9-48-91.9-48-61.9 0-112 50.1-112 112s50.1 112 112 112c35.4 0 66.9-16.4 87.4-42 5.5-6.9 15.6-8 22.5-2.5s8 15.6 2.5 22.5c-26.4 32.9-66.9 54-112.4 54-79.5 0-144-64.5-144-144zm17.3-202.7c16 2.7 31.9 5.3 47.9 8 5.4.9 10.9-1.1 14.6-5.2 11.9-13.3 23.7-26.7 35.6-40-15.6-4.7-31.2-9.3-46.8-14l-51.3 51.3v-.1zm12.5 67 1.8.3-1.5 1.2-.3-1.5zM496.5 192l5.7-6.3c.6 2.1 1.2 4.3 1.8 6.5-2.5-.1-4.9-.2-7.4-.2z" />
    </Svg>
)
export default SvgComponent
