import React from 'react';
import * as Svg from '../../svg';
import { View } from 'react-native';
import { Colors } from "../../styles/Colors";


const IconsGenerator = props => {
    const { name } = props;
    let svgProps = { ...props };
    if (props.size) {
        svgProps.width = props.size;
        svgProps.height = props.size;
    }
    if (!svgProps.color) svgProps.color = Colors.newDesign.mainText;

    switch (name) {
        case 'GoogleWhite':
            return <Svg.IconGoogleLogo {...svgProps} />;
        case 'Google':
            return <Svg.IconGoogle_G {...svgProps} />;
        case 'ArboxLogo':
            return <Svg.IconArboxLogo {...svgProps} />;
        case 'Google':
            return <Svg.IconGoogle {...svgProps} />;
        case 'PhoneCircle':
            return <Svg.IconPhoneCircle {...svgProps} />;
        case 'CalendarCircle':
            return <Svg.IconCalendarCircle {...svgProps} />;
        case 'CartCircle':
            return <Svg.IconCartCircle {...svgProps} />;
        case 'timeClockIn':
            return <Svg.IconTimeClockIn {...svgProps} />;
        case 'envelopeRotateRight':
            return <Svg.IconEnvelope {...svgProps} />;
        case 'bullseyeArrowCircleQuestion':
            return <Svg.IconBullseyeArrowCircleQuestion {...svgProps} />
        case 'addressCardPen':
            return <Svg.IconAddressCardPen {...svgProps} />
        case 'bullseyeArrowRotateRight':
            return <Svg.IconBullseyeArrowRotateRight {...svgProps} />
        case 'lightPaperPlaneRotateRight':
            return <Svg.IconLightPaperPlaneRotateRight {...svgProps} />
        case 'sms':
            return <Svg.IconSms {...svgProps} />
        case 'listRotate':
            return <Svg.IconListRotate {...svgProps} />
        case 'profileLink':
            return <Svg.IconProfileLink {...svgProps} />
        default:
            return <View></View>;
    }
};

export default IconsGenerator;