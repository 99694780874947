import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const usePermissions = () => {
    const user = useSelector((state) => state.user.user);
    const [allPermissions, setAllPermissions] = useState(user?.permissions || []);

    useEffect(() => {
        if(user) {
            setAllPermissions(user.permissions)
        }
    }, [user]);

    /**
     * 
     * @param {string} permission 
     * @returns {boolean} if user has this permission
     */
    const has = (permission) => Array.isArray(permission) ? hasAny(permission) : allPermissions.includes(permission);

    /**
     * 
     * @param {string} permission 
     * @returns {boolean} if user has this permission locked
     */
    const hasLock = (permission) => typeof permission === 'string' && allPermissions.includes(`${permission}Lock`);

    /**
     * 
     * @param {Array<string>} permissions 
     * @returns {boolean} if user has all permissions
     */
    const hasAll = (permissions) => Array.isArray(permissions) && permissions.every(p => allPermissions.includes(p));

    /**
     * 
     * @param {Array<string>} permissions 
     * @returns {boolean} if user has any of the permissions
     */
    const hasAny = (permissions) => Array.isArray(permissions) && permissions.some(p => allPermissions.includes(p));


    /**
     * 
     * @param {string} permission 
     * @param {string} withoutPermission 
     * @returns {boolean} if user has specific permission and doesn't have another permission
     */
    const hasWithout = (permission, withoutPermission) => has(permission) && !has(withoutPermission);

    return { has, hasAny, hasAll, hasLock, hasWithout };
}

export default usePermissions;