// exported from https://www.brandox.com/arbox/colours

export const Colors = {
    newDesign: {
        "darkBlue": "#0F0050",
        "purple": "#aa3aff",
        "yellow": "#ffc400",
        "lightBlue": "#ecf7ff",
        "midLightBlue": "#92eaff",
        "black": "#000000",
        "white": "#ffffff",
        "midDarkBlue": "#272A84",
        "cyanBlue": "#00AFFA",
        "blue": "#54A1F8",
        "mainBlue": "#1936F7",
        "mainBlueHover": "#001CD4",
        "midYellow": "#FFDA67",
        "lightYellow": "#FFECAD",
        "midPurple": "#AA65F0",
        "lightPurple": "#C79EF2",
        "menuBar": "#F9F9F9",
        "lightGrey": "#F6F6F6",
        "mediumGrey": "#D9D9D9",
        "darkGrey": "#9D9D9D",
        "darkerGrey": "#797979",
        "mainText": "#3B3B3B",
        "outerBorder": "#d0d0d06b",
        "lightMango": "#FFFAEB",
        "lightBlueBackground": "#ECF7FF",
        "orangeText": "orange",
        "lightRed": "#FFF8F8",
        "darkOrange": "#FF9000",
        "lightLightPurple": "#F6F0FF",
        "purpleText": "#9C1AFF",
        "signupHelperText": "#737373",
        "green": '#00D181',
        "greyBg": "#f5f5f5",
        "avatarBgColor": "#E0E0E0",
        "leadBorder": "#D094FF",
        "freefitBorder": "#FFE500",
        "staffMemberBorder": "#00CFAD",
        "attendanceNoShowBg": "#F3F3F3",
        "attendanceArrivedBg": "#ECF7FF",
        "attendanceArrivedText": "#54A1F8",
        "attendanceAbsentBg": "#FFF3F3",
        "attendanceAbsentText": "#EA3635",
        "accessControlSuccess": '#DDF7E3',
        "accessControlFailed": '#E7AB9A',
        'red': "#FF0000",
        "summaryReportBg": "#DFF1FF",
        "blueBg": "#ECF7FF",
        "lightGreen": "#E6FAEA",
        "mainGreen": "#0D7F56",
        "redTagBackground": "#FFEDED",
        "redTagText": "#9C1413",
        "tableBackgroundColor": "#F7F7F7"

    },
    scheduleColors: {
        "1": "#D7F9F7",
        "2": "#E4FBE9",
        "3": "#F6FEE6",
        "4": "#FFFEE7",
        "5": "#FEF7E5",
        "6": "#FFEEE6",
        "7": "#FFE5E6",
        "8": "#FFE7FF",
        "9": "#F6E6FF",
        "10": "#E8EDFF",
        "11": "#DEF2FC",
        "12": "#EBFFFF",
    },
    headerColor: '#222222',
    lightBlack: '#343434',
    error: '#D04C4F',
    errorFaded: 'rgba(225,110,114,0.34)',
    borderColor: '#E0E0E0',
    borderColorInput: '#E0E0E0',
    homepageBorderColor: '#d3d3d34d',
    hoverBlueLight: '#9eabff',
    hoverBlueBg: '#e1e5ff',
    switchHover: '#cecece',
    tooltipBg: 'rgba(0,0,0, 1)',
    warningIconColor: '#D63635'
};
