import * as React from "react"
import Svg, { Path } from "react-native-svg"

const IconGoogleLogo = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 17"
        style={{
            enableBackground: "new 0 0 17 17",
        }}
        xmlSpace="preserve"
        {...props}
    >
        <Path
            d="M16.7 8.5c0 2.7-1 4.9-2.5 6.3-1.5 1.3-3.4 2.1-5.4 2.1-3.3 0-6.1-1.9-7.5-4.7C.7 11.1.4 9.8.4 8.5c0-1.3.3-2.6.9-3.7.2-.7 1.5-2.8 4-4C6.3.3 7.5.1 8.7.1c2.1 0 4 .8 5.5 2.1l-2.4 2.4c-.9-.6-1.9-1-3.1-1C6.5 3.5 4.6 5 4 6.9c-.2.5-.3 1.1-.3 1.6s.1 1.1.3 1.5c.6 2 2.5 3.4 4.7 3.4 1.1 0 2-.3 2.8-.7.9-.6 1.5-1.4 1.7-2.5H8.7V7h7.8c.1.5.2 1 .2 1.5z"
            style={{
                fill: "#fff",
            }}
        />
    </Svg>
)

export default IconGoogleLogo;
