import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { VStack, HStack } from 'native-base';
import React, { useMemo } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenLockedPopup } from '../../../redux/rootManagerSlice';
import { EMAIL, PUSH, SMS } from '../../../services/ConstHelper';
import { t } from '../../../services/i18n';
import usePermissions from '../../../services/Permissions';
import { Colors } from '../../../styles/Colors';
import { GlobalStyleAttributes } from '../../../styles/GlobalStyles';
import AppText from '../../common/AppText';
import {Radio} from "antd";
import CustomButtonV2 from "../../common/CustomButtonV2";
import { resetAndClose } from '../Schedule/Popups/CreateEvent/FlowOptions';
import { openUpgrade360Link } from '../../../services/Helpers';


const MessageType = props => {
    const { values, setFieldValue, target } = props
    const dispatch = useDispatch()
    const { has, hasLock } = usePermissions()
    const box = useSelector(state => state.box?.box)
    const stepperProps = useSelector(state => state.stepper?.props)
    const targetUser = stepperProps?.target || target
    const isIL = box?.locations_box[0]?.country_code === 'IL';
    const hasPackage = !/.*(free|trial)+.*/g.test(box?.packages?.name?.toLowerCase());
    const hasWhatapp = useMemo(() => box?.integrations?.find(i => i.name === 'upgrade360') ,[box]);
    const types = targetUser?.messageTypes ? targetUser.messageTypes : [PUSH, SMS, EMAIL]

    const openLockPopup = () => {
        dispatch(setOpenLockedPopup(true))
    }

    const handleWhatsappClick = async () => {
        if (!hasWhatapp) {
            openUpgrade360Link()
        } else {
            setFieldValue('type', 'whatsapp')
        }
    }

    return (
        <Radio.Group name={'message-type-radio'} value={values.type}>
            <VStack space={'1rem'} w={'100%'}>
                {
                    types.map(type => (
                        (type !== EMAIL || has(`${type}Actions`)) && <CustomButtonV2 onClick={() => hasLock(`${type}Actions`) ? openLockPopup() : setFieldValue('type', type)}
                                        key={`event-type-radio-option-${type}`}
                                        iconProps={{iconColor: Colors.borderColor, iconHoverColor: Colors.newDesign.mainBlue}}
                                        override={({color}) => (
                                            <HStack space={'0.5rem'} p={'1rem'} w={'100%'} borderWidth={1} borderColor={values.type === type ? Colors.newDesign.mainBlue : color} borderRadius={4} opacity={hasLock(`${type}Actions`) ? '50%' : '100%'}>
                                                <Radio onClick={(e) => {
                                                    e.stopPropagation()
                                                    type.isLocked ? openLockPopup() : setFieldValue('type', type)
                                                }} value={type} size="sm" disabled={hasLock(`${type}Actions`)}/>
                                                <VStack flex={1}>
                                                    <AppText fontSize={GlobalStyleAttributes.fonts.heading3} boldFont>{t(`send-${type}`)}</AppText>
                                                </VStack>
                                                {hasLock(`${type}Actions`) && <VStack><FontAwesomeIcon icon={'fa-light fa-lock-keyhole'}/></VStack>}
                                            </HStack>)}
                        />
                        )
                    )
                }
                {
                    targetUser?.whatsapp && isIL && <a onClick={() => resetAndClose(() => {}, dispatch)} href={`https://api.whatsapp.com/send?phone=972${target.whatsapp}`} target="_blank" style={{textDecoration: 'none'}}>
                        <HStack space={'1rem'} p={'1rem'} borderWidth={1} borderColor={Colors.borderColor} borderRadius={4}>
                            <Radio value={null} size="sm"/>
                            <VStack flex={1}>
                                <AppText fontSize={GlobalStyleAttributes.fonts.heading3} boldFont>{t('send-whatsapp')}</AppText>
                            </VStack>
                        </HStack>
                    </a>
                }
                {
                    (targetUser?.type === 'schedule' || targetUser?.user) && (isIL || has('viewUpgrade360')) && hasPackage && <CustomButtonV2 onClick={() => handleWhatsappClick()}
                    iconProps={{iconColor: Colors.borderColor, iconHoverColor: Colors.newDesign.mainBlue}}
                    override={({color}) => (
                        <HStack space={'0.5rem'} p={'1rem'} w={'100%'} borderWidth={1} borderColor={values.type === 'whatsapp' ? Colors.newDesign.mainBlue : Colors.borderColor} borderRadius={4} opacity={!hasWhatapp ? '50%' : '100%'}>
                            <Radio value={'whatsapp'} size="sm" disabled={!hasWhatapp}/>
                            <VStack flex={1}>
                                <AppText fontSize={GlobalStyleAttributes.fonts.heading3} boldFont>{t('send-whatsapp')}</AppText>
                            </VStack>
                            {!hasWhatapp && <VStack><FontAwesomeIcon icon={'fa-light fa-lock-keyhole'}/></VStack>}
                        </HStack>
                    )}
                        />
                }
            </VStack>
        </Radio.Group>
    );
};

export default MessageType;

const styles = EStyleSheet.create({
    //style object
});