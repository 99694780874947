import {GlobalConsts} from "../../styles/GlobalConsts";
import { light,solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

export const IconSwitcher = (type,name) => {
    if(type === GlobalConsts.iconPrefix.SOLID){
        const nameTrim = name ? name.replace("fa-solid", "").trim() : null;
        switch (nameTrim){
            case 'fa-bolt':
                return solid('bolt');
            case 'fa-rocket-launch':
                return solid('rocket-launch');
            case 'fa-chart-simple':
                return solid('chart-simple');
            case 'fa-calendar':
                return solid('calendar');
            case 'fa-calendars':
                return solid('calendars');
            case 'fa-wallet':
                return solid('wallet');
            case 'fa-clipboard-list':
                return solid('clipboard-list');
            case 'fa-mobile-notch':
                return solid('mobile-notch');
            case 'fa-chart-line-up':
                return solid('chart-line-up');
            case 'fa-user':
                return solid('user');
            case 'fa-circle-user':
                return solid('circle-user');
            case 'fa-paper-plane':
                return solid('paper-plane');
            case 'fa-lightbulb':
                return solid('lightbulb');
            case 'fa-file-pen':
                return solid('file-pen');
            case 'fa-gear':
                return solid('gear');
            case 'fa-dumbbell':
                return solid('dumbbell');
            case 'fa-chevron-up':
                return solid('chevron-up');
            case 'fa-chevron-down':
                return solid('chevron-down');
            case 'fa-circle-ellipsis':
                return solid('circle-ellipsis');
            case 'fa-bullhorn':
                return solid('bullhorn');
            case 'fa-sparkles':
                return solid('sparkles');
            case 'fa-window-minimize':
                return solid('window-minimize');
            case 'fa-house':
                return solid('house');
            case 'fa-circle-info':
                return solid('circle-info');
            case 'fa-link-horizontal':
                return solid('link-horizontal');
            case 'fa-star':
                return solid('star');
            case 'fa-thumbtack':
                return solid('thumbtack');
            case 'fa-circle-check':
                return solid('circle-check')
            case 'fa-pen':
                return solid('pen');
            case 'fa-trash':
                return solid('trash');
            case 'fa-grip-dots-vertical':
                return solid('grip-dots-vertical');
            case 'fa-x':
                return solid('x');
            case 'fa-check':
                return solid('check');
            default:
                return null;
        }
    }else if(type === GlobalConsts.iconPrefix.LIGHT){
        const nameTrim = name ? name.replace("fa-light", "").trim() : null;
        switch (nameTrim){
            case 'fa-paper-plane':
                return light('paper-plane');
            case 'fa-file-pen':
                return light('file-pen');
            case 'fa-lightbulb':
            case 'bulb':
                return light('lightbulb');
            case 'fa-wallet':
                return light('wallet');
            case 'fa-circle-user':
                return light('circle-user');
            case 'fa-chart-line-up':
                return light('chart-line-up');
            case 'fa-house':
                return light('house');
            case 'fa-pen':
                return light('pen');
            case 'fa-x':
                return light('x');
            case 'fa-xmark':
                return light('xmark');
            case 'fa-calendar-pen':
                return light('calendar-pen');
            case 'fa-calendar-plus':
                return light('calendar-plus');
            case 'fa-calendar-circle-user':
                return light('calendar-circle-user');
            case 'fa-gears':
                return light('gears');
            case 'fa-gear':
                return light('gear');
            case 'fa-calendar-clock':
                return light('calendar-clock');
            case 'fa-calendar':
                return light('calendar');
            case 'fa-calendars':
                return light('calendars');
            case 'fa-location-dot':
                return light('location-dot');
            case 'fa-id-badge':
                return light('id-badge');
            case 'fa-person-running':
                return light('person-running');
            case 'fa-calendar-check':
                return light('calendar-check');
            case 'fa-gauge':
                return light('gauge');
            case 'fa-calendar-xmark':
                return light('calendar-xmark');
            case 'fa-gauge-max':
                return light('gauge-max');
            case 'fa-arrow-right-rotate':
                return light('arrow-right-rotate');
            case 'fa-users':
                return light('users');
            case 'fa-user-clock':
                return light('user-clock');
            case 'fa-calendar-range':
                return light('calendar-range');
            case 'fa-bell':
                return light('bell');
            case 'fa-clipboard-list':
                return light('clipboard-list');
            case 'fa-laptop-code':
                return light('laptop-code');
            case 'fa-mobile-notch':
                return light('mobile-notch');
            case 'fa-chart-simple':
                return light('chart-simple');
            case 'fa-calendar-circle-plus':
                return light('calendar-circle-plus');
            case 'fa-link-simple':
                return light('link-simple');
            case 'fa-trash':
                return light('trash');
            case 'fa-trash-undo':
                return light('trash-undo');
            case 'fa-trash-list':
                return light('trash-list');
            case 'fa-video':
                return light('video');
            case 'fa-square-list':
                return light('square-list');
            case 'fa-thumbtack':
                return light('thumbtack');
            case 'fa-calendar-week':
                return light('calendar-week');
            case 'fa-calendar-day':
                return light('calendar-day');
            case 'fa-calendar-days':
                return light('calendar-days');
            case 'fa-list-ul':
                return light('list-ul');
            case 'fa-chevron-left':
                return light('chevron-left');
            case 'fa-chevron-right':
                return light('chevron-right');
            case 'fa-chevron-up':
                return solid('chevron-up');
            case 'fa-chevron-down':
                return solid('chevron-down');
            case 'fa-user':
                return light('user');
            case 'fa-grid-2':
                return light('grid-2');
            case 'fa-id-card':
                return light('id-card');
            case 'fa-plus':
                return light('plus');
            case 'fa-store':
                return light('store');
            case 'fa-link':
                return light('link');
            case 'fa-mobile':
                return light('mobile');
            case 'fa-rocket-launch':
                return light('rocket-launch');
            case 'fa-globe':
                return light('globe');
            case 'fa-magnifying-glass':
                return light('magnifying-glass');
            case 'fa-circle-question':
                return light('circle-question');
            case 'fa-copy':
                return light('copy');
            case 'fa-bolt':
                return light('bolt');
            case 'fa-close':
                return light('close');
            case 'fa-dumbbell':
                return light('dumbbell');
            case 'fa-angle-down':
                return light('angle-down')
            case 'fa-trash-can':
                return light('trash-can');
            case 'fa-check':
                return light('check');
            case 'fa-credit-card':
                return light('credit-card')
            case 'fa-clone':
                return light('clone')
            case 'fa-lock-keyhole':
                return light('lock-keyhole')
            case 'fa-arrows-left-right':
                return light('arrows-left-right')
            case 'fa-arrows-up-down':
                return light('arrows-up-down')
            case 'fa-arrow-up-from-bracket':
                return light('arrow-up-from-bracket');
            case 'fa-filters':
                return light('filters')
            case 'fa-bullhorn':
                return light('bullhorn');
            case 'fa-user-group':
                return light('user-group');
            case 'fa-link-horizontal':
                return light('link-horizontal');
            case 'fa-arrow-right-from-bracket':
                return light('arrow-right-from-bracket')
            case 'fa-objects-column':
                return light('objects-column');
            case 'fa-circle-pause':
                return light('circle-pause');
            case 'fa-arrow-up-right-from-square':
                return light('arrow-up-right-from-square');
            case 'fa-location-plus':
                return light('location-plus');
            case 'fa-filter':
                return light('filter');
            case 'fa-folder':
                return light('folder');
            case 'fa-circle-info':
                return light('circle-info');
            case 'fa-print':
                return light('print')
            case 'fa-arrow-down-to-bracket':
                return light('arrow-down-to-bracket')
            case 'fa-star':
                return light('star')
            case 'fa-envelope':
                return light('envelope')
            case 'fa-columns-3':
                return light('columns-3')
            case 'fa-angle-right':
                return light('angle-right')
            case 'fa-angle-left':
                return light('angle-left')
            case 'fa-ballot-check':
                return light('ballot-check')
            case 'fa-arrow-up-to-line':
                return light('arrow-up-to-line')
            case 'fa-arrows-to-line':
                return light('arrows-to-line')
            case 'fa-arrow-down-to-line':
                return light('arrow-down-to-line')
            case 'fa-user-pen':
                return light('user-pen')
            case 'fa-unlock':
                return light('unlock')
            case 'fa-bullseye-arrow-circle-question':
                return light('bullseye-arrow')
            case 'fa-arrow-right-to-line':
                return light('arrow-right-to-line')
            case 'fa-arrow-left-to-line':
                return light('arrow-left-to-line')
            case 'fa-ellipsis-vertical':
                return light('ellipsis-vertical')
            case 'fa-arrow-rotate-right':
                return light('arrow-rotate-right')
            case 'fa-memo':
                return light('memo')
            case 'fa-pen-field':
                return light('pen-field');
            case 'fa-bullseye-arrow':
                return light('bullseye-arrow')
            case 'fa-address-card':
                return light('address-card')
            case 'fa-grip-dots':
                return light('grip-dots')
            case 'fa-cube':
                return light('cube')
            case 'fa-code':
                return light('code')
            case 'fa-share':
                return light('share')
            case 'fa-clock-rotate-left':
                return light('clock-rotate-left')
            case 'fa-phone':
                return light('phone')
            default:
                return null;
        }
    } else if(type === GlobalConsts.iconPrefix.REGULAR) {
        const nameTrim = name ? name.replace("fa-regular", "").trim() : null;
        switch (nameTrim) {
            case 'fa-ellipsis-vertical':
                return regular('ellipsis-vertical');
            default:
                return null;
        }
    }
    else if(type === GlobalConsts.iconPrefix.BRANDS) {
        const nameTrim = name ? name.replace("fa-brands", "").trim() : null;
        console.log("Dfdfdf",nameTrim);
        switch (nameTrim) {
            case 'fa-whatsapp':
                return brands('whatsapp');
            default:
                return null;
        }
    }
    else{
        console.log('NOT FOUND', type,name)
        return null;
    }
}