import {Box, HStack, Stack} from "native-base";
import {
    dbDateFormat,
    handleEmailBlur, handlePersonalIdBlur,
    handlePhoneBlur,
    isMobile
} from "../../../../../../services/Helpers";
import FormInput from "../../../../../common/FormInput";
import {t} from "../../../../../../services/i18n";
import AppText from "../../../../../common/AppText";
import {Colors} from "../../../../../../styles/Colors";
import {GlobalStyleAttributes} from "../../../../../../styles/GlobalStyles";
import CustomButtonV2 from "../../../../../common/CustomButtonV2";
import SharedTimepicker from "../../../../../common/SharedTimepicker";
import moment from "moment/moment";
import AdvanceSelect from "../../../../../common/AdvanceSelect";
import {LEAD_SOURCE_SELECT, LEAD_STATUS_SELECT, STAFF_SELECT, USER_FIELDS} from "../../../../../../services/ConstHelper";
import CustomFieldSwitcherCreateModal from "../../../../../common/CustomFieldSwitcherCreateModal";
import {Spin} from "antd";
import CustomCheckbox from "../../../../../common/CustomCheckbox";
import React, {useState} from "react";
import {fetchApi} from "../../../../../../services/HTTP";
import usePermissions from "../../../../../../services/Permissions";
import {useToast} from "react-native-toast-notifications";
import {hideLoader, showLoader} from "../../../../../../redux/rootManagerSlice";

import {useDispatch, useSelector} from "react-redux";
import {validateAfterEmailSymbol} from "../../../../../../services/validationService";

const PersonalDetails = (props) => {
    const {errors, touched, setFieldValue, values, handleChange, setFieldError} = props
    const [userPhoneExist, setUserPhoneExist] = useState(null);
    const [userEmailExist, setUserEmailExist] = useState(null);
    const [userPersonalIdExist, setUserPersonalIdExist] = useState(null);
    const requiredFields = useSelector((state) => state.box.box.settings?.[USER_FIELDS]);
    const dispatch = useDispatch()
    const toast = useToast()
    const {has} = usePermissions()
    const genderOptions = [
        { value: '', label: t('not-specified') },
        { value: 'male', label: t('male') },
        { value: 'female', label: t('female') },
        { value: 'other', label: t('other') }
    ];

    const onPhoneChange = (e) => {
        handleChange('phone')(e)
        setUserPhoneExist(null)
    }

    const onEmailChange = (e) => {
        handleChange('email')(e)
        setUserEmailExist(null)
        if(e === '') {
            setFieldValue('emailValidated', null)
        }
        if(!errors.email) {
            handleEmailBlur(e, setUserEmailExist, setFieldValue)
        } else {
            setFieldValue('emailValidated', false)
        }
    }

    const onPersonalIdChange = (e) => {
        handleChange('personal_id')(e)
        setUserPersonalIdExist(null)
        if(e === '') {
            setFieldValue('personalIdValidated', null)
        }
        if(!errors.personal_id) {
            handlePersonalIdBlur(e, setUserPersonalIdExist, setFieldValue)
        } else {
            setFieldValue('personalIdValidated', false)
        }
    }

    const onDateChange = (name, value) => {
        if (value && moment(value).isValid()) {
            const date = moment(value).format(dbDateFormat);
            setFieldValue(name, date);
        }
    }

    const restoreUserAndOpenProfile = async (usersId, setFunction) => {
        try {
            dispatch(showLoader())
            await fetchApi('usersBoxes/restoreUser', 'POST', { usersId }, false, true)
            setFunction(prev => ({...prev, deleted_at: null}))
            window.open(`/user-profile/${usersId}`, '_blank')
            dispatch(hideLoader())
        } catch (e) {
            dispatch(hideLoader())
            toast.show('restore error', { type: 'danger', duration: 2000 });
        }
    }

    const isRequiredField = (flow, field) => {
        return requiredFields?.[field]?.[`${flow?.toLowerCase()}Required`];
    }

    return (
        <>
            <Stack direction={'column'} space={'5px'}>
                <Stack maxWidth={isMobile ? '100%' : '99%'} direction={isMobile ? 'column' : 'row'} space={'0.5rem'}>
                    {/* FULL NAME */}
                    <Box width={isMobile ? '100%' : '33%'}>
                        <FormInput
                            label={t('firstName')}
                            required
                            value={values.first_name}
                            name={'first_name'}
                            onChangeCallback={handleChange('first_name')}
                            error={(touched.first_name && errors.first_name) ? errors.first_name : null}
                            width={'100%'}
                            containerProps={{ mb: '1rem' }}
                        />
                    </Box>
                    <Box width={isMobile ? '100%' : '33%'}>
                        <FormInput
                            label={t('lastName')}
                            required
                            value={values.last_name}
                            onChangeCallback={handleChange('last_name')}
                            error={(touched.last_name && errors.last_name) ? errors.last_name : null}
                            width={'100%'}
                            containerProps={{ mb: '1rem' }}
                        />
                    </Box>
                    {/* EMAIL */}
                    <Box width={isMobile ? '100%' : '33%'}>
                        <FormInput
                            infoTooltip={t('emailTooltipCreateUser')}
                            label={t('email')}
                            required={isRequiredField('member', 'email')}
                            value={values.email}
                            onChangeCallback={(e) => onEmailChange(e)}
                            width={'100%'}
                            containerProps={{ mb: '1rem' }}
                            error={(touched.email && errors.email) ? errors.email : null}
                            helper={userEmailExist && (userEmailExist.deleted_at ?
                                    <HStack width={'max-content'} space={'0.3rem'}>
                                        <AppText color={Colors.newDesign.darkGrey} variant={GlobalStyleAttributes.textVariants.heading4}>{t(`user-email-restore-${isMobile ? 'mobile' : 'desktop'}`)}</AppText>
                                        <CustomButtonV2 onClick={() => restoreUserAndOpenProfile(userEmailExist.user_fk, setUserEmailExist)}
                                                        iconProps={{iconColor: Colors.newDesign.mainBlue, iconHoverColor: Colors.newDesign.mainBlueHover}}
                                                        override={({color}) => (<AppText variant={GlobalStyleAttributes.textVariants.heading4} color={color}>{t(`click-to-restore-${isMobile ? 'mobile' : 'desktop'}`)}</AppText>)}
                                        />
                                    </HStack>

                         :
                                <HStack width={'max-content'} space={'0.3rem'}>
                                    <AppText color={Colors.newDesign.darkGrey} variant={GlobalStyleAttributes.textVariants.heading4}>{t(`user-email-already-exist-${isMobile ? 'mobile' : 'desktop'}`)}</AppText>
                                    <CustomButtonV2 onClick={() => window.open(`/user-profile/${userEmailExist.user_fk}`, '_blank')}
                                                    iconProps={{iconColor: Colors.newDesign.mainBlue, iconHoverColor: Colors.newDesign.mainBlueHover}}
                                                    override={({color}) => (<AppText variant={GlobalStyleAttributes.textVariants.heading4} color={color}>{t(`click-to-access-${isMobile ? 'mobile' : 'desktop'}`)}</AppText>)}
                                    />
                                </HStack>)
                        }
                            type='email'
                        />
                    </Box>
                </Stack>
                <Stack maxWidth={isMobile ? '100%' : '99%'} direction={isMobile ? 'column' : 'row'} space={'0.5rem'}>
                        {/* PHONE */}
                        <Box width={isMobile ? '100%' : '33%'}>
                            <FormInput
                                label={t('phone')}
                                required={isRequiredField('member', 'phone')}
                                value={values.phone}
                                onChangeCallback={(e) => onPhoneChange(e, setUserPhoneExist, 'phone')}
                                width={'100%'}
                                containerProps={{ mb: '1rem' }}
                                type='tel'
                                error={values.phone && values.phone.match(/[^0-9\+\-]/g) ? t('non-numeric-chars') : values.phone && (values.phone.length > 15 || values.phone.length < 5) ? t('phone-length-limit') : ((touched.phone && errors.phone) ? errors.phone : null)}
                                helper={userPhoneExist &&
                                    <HStack width={'max-content'} space={'0.3rem'}>
                                        <AppText color={Colors.newDesign.darkGrey} variant={GlobalStyleAttributes.textVariants.heading4}>{t(`user-phone-already-exist-${isMobile ? 'mobile' : 'desktop'}`)}</AppText>
                                        <CustomButtonV2 onClick={() => window.open(`/user-profile/${userPhoneExist.user_fk}`, '_blank')}
                                                        iconProps={{iconColor: Colors.newDesign.mainBlue, iconHoverColor: Colors.newDesign.mainBlueHover}}
                                                        override={({color}) => (<AppText variant={GlobalStyleAttributes.textVariants.heading4} color={color}>{t(`click-to-access-${isMobile ? 'mobile' : 'desktop'}`)}</AppText>)}
                                        />
                                    </HStack>}
                                onBlur={() => handlePhoneBlur(values, setUserPhoneExist)}
                            />
                        </Box>
                        {/* BIRTHDAY */}
                        <Box width={isMobile ? '100%' : '33%'}>
                            <FormInput label={t('birthday')} required={isRequiredField('member', 'birthday')} width={'100%'} containerProps={{ mb: '1rem' }} error={(touched.birthday && errors.birthday) ? errors.birthday : null}>
                                <SharedTimepicker type={'date'} value={values.birthday ? moment(values.birthday, dbDateFormat) : null} onChange={(birthday) => onDateChange('birthday', birthday)} textStyle={{ fontSize: GlobalStyleAttributes.fonts.standard16 }} isError={(touched.birthday && errors.birthday) ? errors.birthday : null}/>
                            </FormInput>
                        </Box>
                        <Box width={isMobile ? '100%' : '33%'}>
                        {/* GENDER */}
                        <FormInput label={t('gender')} required={isRequiredField('member', 'gender')} width={'100%'} containerProps={{ mb: '1rem' }} error={(touched.gender && errors.gender) ? errors.gender : null}>
                            <AdvanceSelect dontSort options={genderOptions} initValue={values.gender} onChange={(option) => setFieldValue('gender', option.value ?? null)} isError={(touched.gender && errors.gender) ? errors.gender : null} />
                        </FormInput>
                        </Box>
                    </Stack>
                <Stack maxWidth={isMobile ? '100%' : '99%'} direction={isMobile ? 'column' : 'row'} space={'0.5rem'}>
                    {/* PERSONAL ID */}
                    <FormInput
                        label={t('personalId')}
                        required={isRequiredField('member', 'personal_id')}
                        value={values.personal_id}
                        onChangeCallback={(e) => onPersonalIdChange(e)}
                        width={isMobile ? '100%' : '33%'}
                        containerProps={{ mb: '1rem' }}
                        type='tel'
                        error={values.personal_id && values.personal_id.match(/[^0-9]/g) ? t('non-numeric-chars') : ((touched.birthday && errors.birthday) ? errors.birthday : null)}
                        helper={userPersonalIdExist && (userPersonalIdExist.deleted_at ?
                                <HStack width={'max-content'} space={'0.3rem'}>
                                    <AppText color={Colors.newDesign.darkGrey} variant={GlobalStyleAttributes.textVariants.heading4}>{t(`user-personal-id-already-restore-${isMobile ? 'mobile' : 'desktop'}`)}</AppText>
                                    <CustomButtonV2 onClick={() => restoreUserAndOpenProfile(userPersonalIdExist.user_fk, setUserPersonalIdExist)}
                                                    iconProps={{iconColor: Colors.newDesign.mainBlue, iconHoverColor: Colors.newDesign.mainBlueHover}}
                                                    override={({color}) => (<AppText variant={GlobalStyleAttributes.textVariants.heading4} color={color}>{t(`click-to-restore-${isMobile ? 'mobile' : 'desktop'}`)}</AppText>)}
                                    />
                                </HStack>
                                :
                                <HStack width={'max-content'} space={'0.3rem'}>
                                    <AppText color={Colors.newDesign.darkGrey} variant={GlobalStyleAttributes.textVariants.heading4}>{t(`user-personal-id-already-exist-${isMobile ? 'mobile' : 'desktop'}`)}</AppText>
                                    <CustomButtonV2 onClick={() => window.open(`/user-profile/${userPersonalIdExist.user_fk}`, '_blank')}
                                                    iconProps={{iconColor: Colors.newDesign.mainBlue, iconHoverColor: Colors.newDesign.mainBlueHover}}
                                                    override={({color}) => (<AppText variant={GlobalStyleAttributes.textVariants.heading4} color={color}>{t(`click-to-access-${isMobile ? 'mobile' : 'desktop'}`)}</AppText>)}
                                    />
                                </HStack>

                        )
}
                    />
                    {/* KEY FOB ID */}
                    {has('burnChipActions') && !isMobile &&
                        <FormInput
                        label={t('keyFobID')}
                        value={values.rfid}
                        onChangeCallback={handleChange('rfid')}
                        width={isMobile ? '100%' : '33%'}
                        containerProps={{mb: '1rem'}}
                        type='tel'
                        error={values.rfid && values.rfid.match(/[^0-9]/g) ? t('non-numeric-chars') : null}
                    />}
                    {/* NAME ON INVOICE */}
                    {has('invoiceName') &&
                        <Box width={isMobile ? '100%' : '33%'}>
                            <FormInput
                                infoTooltip={t('nameOnInvoiceTooltipCreateUser')}
                                label={t('nameOnInvoice')}
                                value={values.invoice_name}
                                name={'first_name'}
                                onChangeCallback={handleChange('invoice_name')}
                                error={(touched.invoice_name && errors.invoice_name) ? errors.invoice_name : null}
                                width={'100%'}
                                containerProps={{mb: '1rem'}}
                            />
                        </Box>}
                </Stack>
                {/* CF */}
                <CustomFieldSwitcherCreateModal
                    customFields={values.customFields}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    errors={errors}
                    containerStyle={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        gap: '0.5rem',
                        boxSizing: 'border-box'
                    }}
                />
                {/* SUBSCRIPTIONS */}
                <FormInput containerProps={{ mb: '0' }} label={t('subscriptions')} width={'100%'} labelProps={{ mb: '0.5rem'}}>
                    <CustomCheckbox text={t('subscribe-mailing-list')} isChecked={values.allow_mailing_list === 'yes'} onChange={val => setFieldValue('allow_mailing_list', val ? 'yes' : 'no')}/>
                    <CustomCheckbox text={t('subscribe-sms')} isChecked={values.allow_sms === 'yes'} onChange={val => setFieldValue('allow_sms', val ? 'yes' : 'no')}/>
                </FormInput>
            </Stack>
        </>
    );
}

export default PersonalDetails