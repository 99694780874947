import * as React from "react"
import Svg, { Path } from "react-native-svg"
const SvgComponent = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={16}
        viewBox="0 0 640 512"
        {...props}
    >
        <Path d="M.1 290c-.8-12.2 5.4-23.7 16-29.7 149.3-85.3 298.6-170.6 448-256 10.7-6.1 23.9-5.5 34 1.4s15.4 19.1 13.5 31.2h.1c-8 51.8-15.9 103.5-23.9 155.3-11.4.5-22.5 2.1-33.2 4.7 6.5-42.1 13-84.3 19.5-126.4-89.7 96.5-179.3 193-269 289.6 32.2 13.4 64.4 26.9 96.6 40.3 7.9 3.2 15.6 6.5 23.4 9.7 3.6 14.5 9 28.4 15.9 41.3-17.2-7.2-34.3-14.3-51.5-21.5h-.1l-52.8 66c-8.2 10.2-20.5 16.1-33.6 16.1-23.7 0-43-19.2-43-43v-93c-46.8-19.5-93.5-38.9-140.3-58.4C8.5 312.9.9 302.2.1 290zm31.9-2c47.2 19.7 94.4 39.3 141.6 59L450.5 48.8 32 288zm160 101.3V469c0 6.1 4.9 11 11 11 3.3 0 6.5-1.5 8.6-4.1 15.7-19.6 31.3-39.2 47-58.8L192 389.3zM352 368c0-79.5 64.5-144 144-144 45.3 0 85.6 20.9 112 53.5V240c0-8.8 7.2-16 16-16s16 7.2 16 16v80c0 8.8-7.2 16-16 16h-80c-8.8 0-16-7.2-16-16s7.2-16 16-16h43.9c-20.2-29-53.9-48-91.9-48-61.9 0-112 50.1-112 112s50.1 112 112 112c35.4 0 66.9-16.4 87.4-42 5.5-6.9 15.6-8 22.5-2.5s8 15.6 2.5 22.5c-26.4 32.9-66.9 54-112.4 54-79.5 0-144-64.5-144-144z" />
    </Svg>
)
export default SvgComponent