import CustomCheckbox from "../../../../../shared/components/common/CustomCheckbox";
import {ColorPicker, Space} from "antd";
import {t} from "../../../../../shared/services/i18n";
import InfoTooltip from "../../../../../shared/components/common/InfoTooltip";
import FormInput from "../../../../../shared/components/common/FormInput";
import React, {useEffect} from "react";
import usePermissions from "../../../../../shared/services/Permissions";
import {Circle, HStack, VStack} from "native-base";
import AppText from "../../../../../shared/components/common/AppText";
import {GlobalStyleAttributes} from "../../../../../shared/styles/GlobalStyles";
import CategoryColors from "../../../../../shared/styles/CategoryColors";
import CustomColorPicker from "../../../../../shared/components/common/CustomColorPicker";

const CreateTaskType = (props) => {
    const { values, handleChange, setFieldValue} = props;
    const {has} = usePermissions()

    const setCustomColor = (color) => {
        if(color.toHexString() === '#000000') {
            return
        }
        setFieldValue('task_type_color', color.toHexString())
    }

    return (
        <VStack space={'24px'}>
            <FormInput
                label={t('taskTypeNameField')}
                width={'100%'}
                value={values.type}
                containerProps={{ mb: 0 }}
                onChangeCallback={handleChange('type')}
                maxLength={30}
                showCount
            />
            <VStack space={'0.2rem'} >
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('color')}</AppText>
                <CustomColorPicker colors={CategoryColors} setCustomColor={setCustomColor} customValue={values.task_type_color} onColorPick={(value) => setFieldValue('task_type_color', value)}/>
            </VStack>
            <VStack space={'0.2rem'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('showAt')}</AppText>
                <CustomCheckbox isChecked={values.check_in} onChange={val => setFieldValue('check_in', val)} text={
                    <HStack>
                        {t('show-task-check-in')}
                        <InfoTooltip title={t('show-task-check-in-tooltip')} />
                    </HStack>
                }/>
                {has('burnChipActions') && <CustomCheckbox isChecked={values.access_control} onChange={val => setFieldValue('access_control', val)} text={
                    <HStack>
                        {t('show-task-access-control')}
                        <InfoTooltip title={t('show-task-access-control-tooltip')} />
                    </HStack>
                }/>}
            </VStack>
        </VStack>
    )
}

export default CreateTaskType