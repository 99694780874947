import {HStack} from "native-base";
import CategoryColors from "../../styles/CategoryColors";
import CustomButtonV2 from "./CustomButtonV2";
import {ColorPicker} from "antd";
import {Colors} from "../../styles/Colors";
import {t} from "i18next";
import {useEffect, useState} from "react";

const CustomColorPicker = (props) => {
    const {colors, onColorPick, customValue} = props
    const [customColor, setCustomColor] = useState('#000000')

    const onTagColorClick = (color) => {
        onColorPick(color)
        setCustomColor('#000000')
    }

    const onChangeCustomColor = (color) => {
        setCustomColor(color)
        onColorPick(color.toHexString())
    }



    return (
        <HStack justifyContent={'space-evenly'} flex={1} alignItems={'center'}>
            {Object.values(colors).map(color => (
                <CustomButtonV2 onClick={() => onTagColorClick(color)}
                                key={`task-type-color-${color}`}
                                override={() => <ColorPicker defaultValue={color} disabled style={{width: '32px',minWidth: '32px', height: '32px', cursor: 'pointer', background: 'white', border: `1px solid ${(customValue === color && customColor === '#000000') ? Colors.newDesign.mainBlue : Colors.newDesign.avatarBgColor}`}} rootClassName={'create-task-type-picker'}/>}
                />
            ))}
            <ColorPicker showText={() => <>{t('custom')}</>}
                         rootClassName={'create-task-type-picker'}
                         value={customColor}
                         style={{width: 'fit-content', borderColor: customColor !== '#000000' ? Colors.newDesign.mainBlue : Colors.borderColor, maxHeight: '32px'}}
                         onChangeComplete={(color) => onChangeCustomColor(color)}
                         disabledAlpha
            />
        </HStack>
    )
}

export default CustomColorPicker