import { useState, useEffect } from 'react';
import usePermissions from "../services/Permissions"; // Import the necessary dependencies

const useCheckPermissions = () => {
    const {has, hasAny, hasWithout} = usePermissions();
    const [permissions, setPermissions] = useState({
        hasAddPermission: hasAny(['scheduleAddNewClass', 'scheduleAddNewEvent']),
        hasViewSettingsGearPermission: hasAny(['viewSettingsSchedule', 'scheduleViewSettingsHeader']),
        hasMassActionPermission: hasAny(['scheduleViewMultipleActions', 'multipleActionsSchedule']),
        hasBookingServicesPermission: has('scheduleViewBookingServices'),
        hasSearchPermissionBooking: hasAny(['scheduleUserActions','scheduleBookingSearchBar']),
        hasSearchPermissionStandby: hasAny(['scheduleStandByActions','scheduleWaitingSearchBar']),
        hasSearchPermissionHug: has('HugBookingSearchBar'),
        hasReviveEventPermission: hasAny(['scheduleEditClassDetails','scheduleAddNewClass','restoreCanceledEvent']),
        hasBookAppointmentPermission: hasAny(['availability','scheduleAddNewClass','scheduleAddNewEvent']),
        hasCancelDeleteClassPermission: hasAny(['scheduleCancelClass','scheduleCancelDeleteEvent']),
        hasEditClassPermission: hasAny(['scheduleEditClassDetails','scheduleEditEvent']),
        hasDeleteUserFromEventPermission: hasAny(['scheduleDeleteUserFromEvent','deleteAthleteFromSchedule']),
        hasViewManagePermission: hasAny(['viewScheduleClassPopup','viewManageEvent']),
        hasCancelDeleteSchedulePermission: hasAny(['scheduleCancelClass','scheduleCancelDeleteEvent']),
        hasEditSchedulePermission: hasAny(['scheduleEditClassDetails','scheduleEditEvent']),
        hasCreateEventPermission: hasAny(['scheduleAddNewClass','scheduleAddNewEvent']),
        hasViewScheduleSettings: has('scheduleViewScheduleSettings'),
        hasEditSpacePermission: has('editSpace'),
        hasLimitedCoachViewSchedule: hasWithout('coachViewSchedule', 'viewScheduleV5'),
    });

    return {...permissions};
};

export default useCheckPermissions;