import * as React from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        style={{
            enableBackground: "new 0 0 43 41",
        }}
        viewBox="0 0 43 41"
        {...props}
    >
        <style>
            {".st1{fill:none;stroke:#3b3b3b;stroke-width:1.2;stroke-linecap:round}"}
        </style>
        <path
            d="M11.6 39.2c.1.2.2.3.4.4.2.1.4 0 .5-.1l5.4-3.8h19.4c2.3 0 4.3-1.8 4.3-4.1v-26c0-2.3-1.9-4.1-4.3-4.1H5.7c-2.3 0-4.3 1.8-4.3 4.1v26c0 2.3 1.9 4.1 4.3 4.1h5l.9 3.5z"
            style={{
                fill: "#fff",
                stroke: "#3b3b3b",
                strokeWidth: 1.2,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 10,
            }}
        />
        <path d="M8 9h27M8 15h27M8 21h15" className="st1" />
    </svg>
)
export default SvgComponent
