import React, {useEffect, useRef, useState} from 'react';
import {HStack, Pressable, VStack} from "native-base";
import {Colors} from "../../styles/Colors";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {GlobalConsts} from "../../styles/GlobalConsts";
import AppText from "./AppText";
import {GlobalStyleAttributes} from "../../styles/GlobalStyles";
import CustomButtonV2 from "./CustomButtonV2";
import InfoTooltip from './InfoTooltip';
import { setOpenLockedPopup } from '../../redux/rootManagerSlice';
import { useDispatch } from 'react-redux';
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

const FoldableSettingSection = (props) => {
    const { isOpen, onToggle, header, children, infoTooltip, hasLock, summary, error, errorIcon } = props;
    const dispatch = useDispatch();
    const contentRef = useRef(null);
    const [contentHeight, setContentHeight] = useState(0);

    useEffect(() => {
        if (contentRef.current) {
            const height = contentRef.current.scrollHeight;
            setContentHeight(height);
        }
    }, [children]);

    const contentStyle = {
        transition: 'max-height 0.3s ease-out',
        maxHeight: isOpen ? `${contentHeight}px` : '0',
        overflow: 'hidden'
    };

    return (
        <VStack p={'1rem'} space={'0.5rem'} borderWidth={1} borderColor={Colors.borderColor} borderRadius={4} opacity={hasLock ? 0.5 : 1}>
            <CustomButtonV2 onClick={hasLock ? () => dispatch(setOpenLockedPopup(true)) : onToggle}
                            override={() => (
                                <HStack justifyContent={'space-between'} w={'100%'}>
                                    <HStack alignItems={'center'}>
                                        <AppText fontSize={GlobalStyleAttributes.fonts.heading3} boldFont>{header}</AppText>
                                        {infoTooltip && <InfoTooltip title={infoTooltip}/>}
                                        {errorIcon && <div style={{margin: '0 5px'}}><FontAwesomeIcon color={Colors.warningIconColor} icon={solid('triangle-exclamation')} size={GlobalConsts.iconSize.XS}/></div>}
                                    </HStack>
                                    {hasLock ? <FontAwesomeIcon icon={'fa-light fa-lock-keyhole'} size={GlobalConsts.iconSize.XSS}/> :
                                    <div style={{transition: 'transform 0.3s ease-out', transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)'}}>
                                        <FontAwesomeIcon icon={`fa-light fa-chevron-down`} size={GlobalConsts.iconSize.XSS}/>
                                    </div>}
                                </HStack>)}
            />
            <div ref={contentRef} style={contentStyle}>
                {children}
            </div>
            {summary && <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.newDesign.darkGrey}>{summary}</AppText>}
            {error && <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.newDesign.error}>{error}</AppText>}
        </VStack>
);
};

export default FoldableSettingSection;

