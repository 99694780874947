import usePermissions from "../../../services/Permissions";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import {t} from "../../../services/i18n";
import {setOpenLockedPopup} from "../../../redux/rootManagerSlice";
import {getLocale, isActive, openUpgrade360Link} from "../../../services/Helpers";
import {fetchApi} from "../../../services/HTTP";
import FormInput from "../../common/FormInput";
import SimpleInput from "../../common/SimpleInput";
import {Box, Button, HStack, Spinner, VStack} from "native-base";
import {Flex, Radio} from "antd";
import AppText from "../../common/AppText";
import InfoTooltip from "../../common/InfoTooltip";
import AdvanceSelect from "../../common/AdvanceSelect";
import {EMAIL, ITEMS_SELECT, MESSAGE_TEMPLATE_SELECT, SMS} from "../../../services/ConstHelper";
import CustomCheckbox from "../../common/CustomCheckbox";
import {Colors} from "../../../styles/Colors";
import CustomButtonV2 from "../../common/CustomButtonV2";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {GlobalStyleAttributes} from "../../../styles/GlobalStyles";
import _ from "lodash";
import groupBy from 'lodash-es/groupBy';

const SendLink = (props) => {
    const { handleChange, values, touched, errors, target, setFieldValue, displayProps } = props;
    const {has, hasLock} = usePermissions()
    const dispatch = useDispatch()
    const box = useSelector(state => state.box?.box)
    const isIL = box?.locations_box[0]?.country_code === 'IL';
    const [smsBalance, setSmsBalance] = useState(null);
    const [membershipTypesShowInApp, setMembershipTypesShowInApp] = useState([])
    const [membershipTypesHideInApp, setMembershipTypesHideInApp] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const hasPackage = !/.*(free|trial)+.*/g.test(box?.packages?.name?.toLowerCase());
    const hasWhatsapp = useMemo(() => box?.integrations?.find(i => i.name === 'upgrade360'), [box]);
    const charCount = values.message_content?.length || 0;
    const smsUnitCount = isIL ? 133 : 70;
    const [messageTemplates] = useState(box.message_template?.filter(isActive)?.map(template => ({...template, value: template.id, label: template.name, text: template.text.replace('{{#firstName#}}', `[${t('firstName')}]`).replace('{{#lastName#}}', `[${t('lastName')}]`)})))
    const tags = displayProps.tags

    useEffect(() => {
        fetchItems()
    }, [])

    const onTagClick = (label) => {
        if (`${values.message_content || ''} [${label}]`.length <= 320) {
            setFieldValue('message_content', `${values.message_content || ''}[${label}]`)
        }
        document?.getElementById('text-area-message')?.focus()
    }

    useEffect(() => {
        getSmsBalance()
    }, []);

    const openLockPopup = () => {
        dispatch(setOpenLockedPopup(true))
    }

    const handleWhatsappClick = async (val) => {
        if (!hasWhatsapp) {
            openUpgrade360Link()
        } else {
            setFieldValue('send_whatsapp', val)
        }
    }
    const handleEmailClick = async (val) => {
        if (hasLock('emailActions')) {
            openLockPopup()
        } else {
            setFieldValue('send_email', val)
        }
    }

    const getSmsBalance = async () => {
        const res = await fetchApi('boxes/getSmsBalance', 'POST', null, false, true);
        setSmsBalance(res || 0);
    }

    const fetchItems = async () => {
        const resultsShow = await fetchApi('membership/searchForMembershipTypes', 'post', { show_in_app: 1 }, false, true);
        const resultsHide = await fetchApi('membership/searchForMembershipTypes', 'post', { show_in_app: 0 }, false, true);
        if (resultsShow || resultsHide) {
            filterAndSortItems(resultsHide, setMembershipTypesHideInApp)
            filterAndSortItems(resultsShow, setMembershipTypesShowInApp)
        }
        return []
    }

    const filterAndSortItems = (items, setFunc) => {
        let results = items.reduce((acc, item) => [...acc, {...item, label: item.name, value: item.id}],[]);

        results = _.sortBy(results, [
            (item) => {
                if (/^[A-Za-z]/.test(item.name)) return 1;
                if (/^[\u0590-\u05FF]/.test(item.name)) return 2;
                if (/^\d/.test(item)) return 3;
                return 4;
            },
            (item) => item.name.toLowerCase()
        ]);
        results = groupItems(results)
        setFunc(results)
    }

    const groupItems = (options) => {
        let group = groupBy(options, 'type')
        const res = Object.keys(group).reduce((acc, groupKey) => ({...acc, [groupKey]: {...acc[groupKey], options: group[groupKey]}}),
            {
                'plan': {
                    label: t('planItemsSelect'),
                    options: []
                },
                'session': {
                    label: t('sessionItemsSelect'),
                    options: []
                },
                'item': {
                    label: t('productItemsSelect'),
                    options: []
                }
            })
        return Object.values(res);
    }

    const onMembershipTypeChange = (data) => {
        if(data) {
            setFieldValue('item_id', data?.id)
            setFieldValue('item_type', data?.type)
        } else {
            setFieldValue('item_id', null)
            setFieldValue('item_type', null)
        }
    }

    const onChooseTemplate = (option) => {
        setSelectedTemplate(option.name)
        setFieldValue('digital_form_id', option.digital_form_id)
        setFieldValue('message_content', option.text)
        setFieldValue('subject', option.subject);

    }

    return (
        <>
            {/* user */}
            <FormInput
                label={t('specific-user')}
                width={'100%'}
                containerProps={{ mb: '24px' }}
                error={(touched.user_fk && errors.user_fk) || null}>
                <SimpleInput value={target.user?.full_name} disabled />
            </FormInput>
            {/* item */}
            {displayProps.showItems && <FormInput label={t('chooseItemToSend')} containerProps={{mb: '24px'}}
                        error={(!values.item_id && errors.item_id && touched.item_id ? errors.item_id : '')}>
                <VStack space={'9px'}>
                    <HStack>
                        <HStack>
                            <Radio.Group value={values.available_on_app} onChange={(e) => {
                                setFieldValue('available_on_app', e.target.value)
                                setFieldValue('item_id', null)
                            }}>
                                <HStack space={'1.2rem'}>
                                    <Radio value={true} size="sm">
                                        <AppText>{t('availableOnApp')}</AppText>
                                    </Radio>
                                    <Radio value={false} size="sm">
                                        <HStack>
                                            <AppText>{t('notAvailableOnApp')}</AppText>
                                            <InfoTooltip title={t('sendMembershipLinkInfo')}/>
                                        </HStack>
                                    </Radio>
                                </HStack>
                            </Radio.Group>
                        </HStack>
                    </HStack>
                    <Box flexGrow={1}>
                        <AdvanceSelect dontSort isClearable={true}
                                       options={values.available_on_app ? membershipTypesShowInApp : membershipTypesHideInApp}
                                       initValue={values.item_id} type={ITEMS_SELECT} onChange={onMembershipTypeChange}
                                       placeholder={t('selectItemForPurchase')}
                                       showInApp={values.available_on_app ? 1 : 0}
                                       isError={!values.item_id && errors.item_id && touched.item_id}/>
                    </Box>
                </VStack>
            </FormInput>}
            {/* types */}
            <FormInput label={t('message-type')} containerProps={{ mb: '24px' }} error={(touched.send_sms && errors.send_sms) || null}>
                {displayProps.showPushOption && <HStack>
                    <CustomCheckbox text={t('send-via-push')} isChecked={values.send_push}
                                    onChange={val => setFieldValue('send_push', val)}/>
                </HStack>}
                <HStack>
                    <CustomCheckbox text={
                        <HStack space={'0.2rem'} alignItems={'center'} height={'1.8rem'}>{t('send-via-sms') + ' '} {
                            <HStack alignItems={'center'}>
                                ({smsBalance !== null ? <AppText>{`${t('your-text-message-balance')}: ${smsBalance?.toLocaleString(getLocale()) || 0}. `}</AppText> : <Spinner color={Colors.newDesign.darkGrey} />}
                                <CustomButtonV2 onClick={() => window.open('/reports-v5/communication?action=smsPurchasePage', '_blank')}
                                                type={'link'}
                                                style={{ width: 'max-content' }}
                                                text={t('purchase-more')}
                                                textProps={{ color: Colors.newDesign.mainBlue }}
                                />
                                )</HStack>
                        }</HStack>
                    } isChecked={values.send_sms} onChange={val => setFieldValue('send_sms', val)} />
                </HStack>
                {has('emailActions') &&
                    <HStack>
                        <CustomCheckbox style={hasLock(`emailActions`) ? { opacity: 0.5 } : {}} text={
                            <HStack height={'1.8rem'} alignItems={'center'} space={'0.4rem'}>{t('send-via-email')}{hasLock(`emailActions`) && <FontAwesomeIcon icon={'fa-light fa-lock-keyhole'} />}</HStack>
                        } isChecked={values.send_email} onChange={handleEmailClick} />
                    </HStack>}
                {(isIL || has('viewUpgrade360')) && hasPackage &&
                    <HStack>
                        <CustomCheckbox
                            disabled={!hasWhatsapp}
                            style={!hasWhatsapp ? { opacity: 0.5 } : {}}
                            text={<CustomButtonV2 onClick={() => !hasWhatsapp && handleWhatsappClick()} override={() => <HStack height={'1.8rem'} alignItems={'center'} space={'0.4rem'}>
                                {t('send-via-whatsapp')}{!hasWhatsapp && <FontAwesomeIcon icon={'fa-light fa-lock-keyhole'} style={{opacity: '0.5'}}/>}
                            </HStack>} />}
                            isChecked={values.send_whatsapp}
                            onChange={handleWhatsappClick}
                        />
                    </HStack>}
            </FormInput>
            {/* template */}
            {<FormInput containerProps={{ mb: '24px' }} label={t('template')} width={'100%'} >
                <AdvanceSelect dontSort type={MESSAGE_TEMPLATE_SELECT} options={messageTemplates} initValue={selectedTemplate} onChange={onChooseTemplate} />
            </FormInput>}
            {/* subject */}
            {has('emailActions') && !hasLock('emailActions') && <FormInput
                label={t('subject')}
                value={values.subject}
                onChangeCallback={handleChange('subject')}
                error={(touched.subject && errors.subject) || null}
                width={'100%'}
                maxLength={40}
                showCount
                containerProps={{ mb: '24px' }}
                placeholder={t(`subject-email`)}
            />}
            {/* message content */}
            <FormInput label={t('messageContent')} width={'100%'} containerProps={{ mb: '24px' }}
                       helper={<Flex vertical>
                           {values.type === SMS &&<AppText color={Colors.newDesign.darkGrey} fontSize={GlobalStyleAttributes.fonts.infoText}>{t('sms-message-content-helper')}</AppText>}
                           {values.digital_form_id &&<AppText color={Colors.newDesign.darkGrey} fontSize={GlobalStyleAttributes.fonts.infoText}>{t('digital-form-message-content-helper')}</AppText>}
                       </Flex>}                       error={(touched.message_content && errors.message_content) ? errors.message_content : null}
                       topEndElement={<AppText variant={GlobalStyleAttributes.textVariants.heading4} color={Colors.newDesign.darkGrey}>{`${charCount % 320}/${320}${` | ${parseInt(charCount / smsUnitCount) + 1} ${t('messages')}`}`}</AppText>}>
                <Box>
                    <HStack borderColor={Colors.borderColor} flexWrap={'wrap'} borderWidth={1} p={'0.3rem'} space={'0.3rem'} borderRadius={4} top={'1px'} alignItems={'center'}>
                        <AppText variant={GlobalStyleAttributes.textVariants.heading4}>{`${t('tags')}:`}</AppText>
                        {tags.map(tag =>
                            <Button
                                bgColor={Colors.newDesign.menuBar}
                                borderColor={((tag.id === 'membership_link' || tag.id === 'convert_link') && touched.message_content && errors.message_content === t('link-is-required')) ? '#D63635' : Colors.newDesign.menuBar}
                                borderWidth={'1px'}
                                onPress={() => onTagClick(tag.label)}
                                key={tag.id}
                                width={'auto'}
                                mb={'0.3rem'}
                                size={'md'} h={8}>
                                <AppText variant={GlobalStyleAttributes.textVariants.heading4}>{tag.label}</AppText>
                            </Button>
                        )}
                    </HStack>
                    <SimpleInput inputType={'multiline'}
                                 rows={6}
                                 id={'text-area-message'}
                                 maxLength={320}
                                 placeholder={t('add-message-here')}
                                 value={values.message_content}
                                 onChangeCallback={(val) => setFieldValue('message_content', val)}
                                 status={touched.message_content && errors.message_content ? 'error' : null}
                    />
                </Box>
            </FormInput>
        </>
    );
}

export default SendLink