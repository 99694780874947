import * as React from "react"
import Svg, { Path } from "react-native-svg"

const IconTimeClockIn = (props) => (
    <Svg width="17" height="16" viewBox="0 0 17 16" fill="none"  {...props}>
      <g clip-path="url(#clip0_6003_11816)">
        <path d="M12.7 7.3999C10.4 7.3999 8.5 9.2999 8.5 11.5999C8.5 13.8999 10.4 15.7999 12.7 15.7999C15 15.7999 16.9 13.8999 16.9 11.5999C16.9 9.2999 15 7.3999 12.7 7.3999ZM14.1 12.0999H12.7C12.5 12.0999 12.2 11.8999 12.2 11.5999V9.7999C12.2 9.5999 12.4 9.2999 12.7 9.2999C13 9.2999 13.2 9.4999 13.2 9.7999V11.1999H14.2C14.4 11.1999 14.7 11.3999 14.7 11.6999C14.6 11.8999 14.4 12.0999 14.1 12.0999Z" fill="#0AC144"/>
        <path d="M8.7 14.9H1.9C1.4 14.9 1 14.5 1 14V6.50005H12.2C12.3 6.50005 12.5 6.50005 12.7 6.50005C12.8 6.50005 13 6.50005 13.2 6.50005V4.60005C13.2 3.60005 12.4 2.70005 11.3 2.70005H10.4V1.30005C10.4 1.10005 10.2 0.800049 9.9 0.800049C9.6 0.800049 9.4 1.00005 9.4 1.30005V2.70005H3.8V1.30005C3.8 1.10005 3.6 0.800049 3.3 0.800049C3 0.800049 2.8 1.00005 2.8 1.30005V2.70005H1.9C0.8 2.70005 0 3.50005 0 4.60005V14C0 15.1 0.8 15.9 1.9 15.9H9.7C9.3 15.6 9 15.3 8.7 14.9ZM1 4.60005C1 4.10005 1.4 3.70005 1.9 3.70005H11.3C11.8 3.70005 12.2 4.10005 12.2 4.60005V5.50005H1V4.60005Z" fill="#3B3B3B"/>
      </g>
      <defs>
        <clipPath id="clip0_6003_11816">
          <rect width="17" height="16" fill="white"/>
        </clipPath>
      </defs>
    </Svg>

)



export default IconTimeClockIn;