import * as React from "react"
import Svg, { Circle, Path, Rect } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    width={53}
    height={53}
    viewBox="0 0 53 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={26.5} cy={26.5} r={26.5} fill="#54A1F8" />
    <Path
      d="M36.94 12.09H15.547a2.494 2.494 0 0 0-2.494 2.494V48.5c0 1 7.447 4 13.947 4 2.546 0 6.5-1 6.5-1l5.934-2.5V14.584a2.494 2.494 0 0 0-2.494-2.495Z"
      fill="#fff"
    />
    <Path
      d="M40.144 14.908v1.572h.488v4.464h-.488v1.015h.488v4.465h-.488v1.015h.488v4.466h-.488V48.5L34 51.5l-7 .945-3.5.055-4-1L18 51l-4.804-2-.851-.702V24.516H12v-4.46h.349v-5.148A2.907 2.907 0 0 1 15.256 12h21.98a2.906 2.906 0 0 1 2.908 2.908Zm-7.602-1.82-.858 1.282a1.385 1.385 0 0 1-1.153.613h-8.575a1.385 1.385 0 0 1-1.154-.613l-.858-1.282h-3.983a2.909 2.909 0 0 0-2.907 2.909V48.5l1.446 1 1 .5s0-.5 1.5.5 8.73 2.002 9.5 2c.77-.002 7.652-1.074 7.652-1.074l2.376-.926L39.43 49V15.997a2.909 2.909 0 0 0-2.907-2.909h-3.981Z"
      fill="#3B3B3B"
    />
    <Rect
      x={15.5}
      y={25.5}
      width={4}
      height={4}
      rx={0.5}
      fill="#fff"
      stroke="#3B3B3B"
    />
    <Rect
      x={15.5}
      y={32.5}
      width={4}
      height={4}
      rx={0.5}
      fill="#fff"
      stroke="#3B3B3B"
    />
    <Rect
      x={21.5}
      y={25.5}
      width={4}
      height={4}
      rx={0.5}
      fill="#fff"
      stroke="#3B3B3B"
    />
    <Rect
      x={21.5}
      y={32.5}
      width={4}
      height={4}
      rx={0.5}
      fill="#fff"
      stroke="#3B3B3B"
    />
    <Rect
      x={27.5}
      y={25.5}
      width={4}
      height={4}
      rx={0.5}
      fill="#fff"
      stroke="#3B3B3B"
    />
    <Rect
      x={27.5}
      y={32.5}
      width={4}
      height={4}
      rx={0.5}
      fill="#fff"
      stroke="#3B3B3B"
    />
    <Rect
      x={15.5}
      y={39.5}
      width={4}
      height={4}
      rx={0.5}
      fill="#fff"
      stroke="#3B3B3B"
    />
    <Rect
      x={33.5}
      y={25.5}
      width={4}
      height={4}
      rx={0.5}
      fill="#fff"
      stroke="#3B3B3B"
    />
    <Rect
      x={33.5}
      y={32.5}
      width={4}
      height={4}
      rx={0.5}
      fill="#fff"
      stroke="#3B3B3B"
    />
    <Rect
      x={21.5}
      y={39.5}
      width={4}
      height={4}
      rx={0.5}
      fill="#fff"
      stroke="#3B3B3B"
    />
    <Circle cx={26.5} cy={26.5} r={26} stroke="#3B3B3B" />
  </Svg>
)

export default SvgComponent
