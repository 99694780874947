import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    width={53}
    height={53}
    viewBox="0 0 53 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={26.5} cy={26.5} r={26} fill="#54A1F8" stroke="#3B3B3B" />
    <Path
      d="M13.675 40.083H32.69a1.26 1.26 0 0 0 1.246-1.08l3.912-25.492h4.896a1.256 1.256 0 0 0 0-2.511H36.76a1.256 1.256 0 0 0-1.242 1.08l-3.916 27.537H13.675c-.333 0-.888 0-.888.368.746.232 7.882-.468 7.882-.135s-7.882-.656-7.882-.134c0 .27.555.367.888.367Z"
      fill="#fff"
      stroke="#3B3B3B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M34.573 34.785H32.12l.28-1.793h2.457l-.284 1.793Z"
      fill="#3B3B3B"
      stroke="#3B3B3B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11.118 33.557H35.12l2.118-16.514H8.999l2.119 16.514Z"
      fill="#fff"
      stroke="#3B3B3B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M26.5 43a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z"
      fill="#3B3B3B"
      stroke="#3B3B3B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M26.5 43a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z"
      fill="#fff"
      stroke="#3B3B3B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16.5 43a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z"
      fill="#3B3B3B"
      stroke="#3B3B3B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16.5 43a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z"
      fill="#fff"
      stroke="#3B3B3B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12.17 19.735H9.56M15.44 19.735H13.59M36.571 19.735H16.86"
      stroke="#3B3B3B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default SvgComponent
