import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {
    createdStatusId,
    LEAD_SOURCE_SELECT,
    LEAD_STATUS_SELECT,
    SITE_TRIAL_SETTINGS
} from "../../../../../../services/ConstHelper";
import {HStack, VStack} from "native-base";
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../styles/GlobalStyles";
import {t} from "../../../../../../services/i18n";
import InfoTooltip from "../../../../../common/InfoTooltip";
import AdvanceSelect from "../../../../../common/AdvanceSelect";
import FormInput from "../../../../../common/FormInput";
import {isActive} from "../../../../../../services/Helpers";
import orderBy from "lodash-es/orderBy";

const SiteTrialSettings = (props) => {
    const { values, errors, setFieldValue, touched } = props;
    const box = useSelector((state) => state.box.box);
    const boxStatusOptions = useMemo(() => {
        if(box?.box_statuses) {
            const res = box?.box_statuses?.filter(isActive).map(status => ({value: status.id, label: status.name}))
            return orderBy(res, [item => item?.label?.toLowerCase()], ['asc'])
        }
    }, [box])
    const boxSourceOptions = useMemo(() => {
        if(box?.box_sources) {
            const res = box?.box_sources?.filter(isActive).map(source => ({value: source.id, label: source.name}))
            return orderBy(res, [item => item?.label?.toLowerCase()], ['asc'])
        }
    }, [box])

    const getSection = (sectionName) => {
        const defaultStatusOption = sectionName === 'newLead' ? {label: t('created'), value: createdStatusId} : {label: t('keep-current-status'), value: 'keep'}
        const defaultSourceOption = sectionName === 'newLead' ? null : {label: t('keep-current-source'), value: 'keep'}
        let statusOptions = [defaultStatusOption, ...boxStatusOptions]
        let sourceOptions = defaultSourceOption ? [defaultSourceOption, ...boxSourceOptions] : boxSourceOptions
        return (
            <VStack space={'0.5rem'}>
                <HStack>
                    <AppText fontSize={'15px'} boldFont>{t(`section-${sectionName}-header`)}</AppText>
                    <InfoTooltip title={t(`section-${sectionName}-tooltip`)}/>
                </HStack>
                <VStack space={'0.425rem'}>
                    <FormInput label={t('statusLeadLabel')} width={'100%'} error={(touched[sectionName] && errors[sectionName] && touched[sectionName].status_id && errors[sectionName].status_id) ? errors[sectionName].status_id : null} containerProps={{mb: 0}}>
                        <AdvanceSelect type={LEAD_STATUS_SELECT} placeholder={t('select-status-placeholder')} initValue={sectionName === 'existingLead' && !values[sectionName].status_id ? 'keep' : values[sectionName].status_id} options={statusOptions} onChange={(select) => setFieldValue(`${sectionName}.status_id`,sectionName === 'existingLead' && select.value === 'keep' ? null : select.value)} isError={touched[sectionName] && errors[sectionName] && touched[sectionName].status_id && errors[sectionName].status_id} menuShouldScrollIntoView={false} dontSort={true}/>
                    </FormInput>
                    <FormInput label={t('sourceLeadLabel')} width={'100%'} error={(touched[sectionName] && errors[sectionName] && touched[sectionName].source_id && errors[sectionName].source_id) ? errors[sectionName].source_id : null} containerProps={{mb: 0}}>
                        <AdvanceSelect type={LEAD_SOURCE_SELECT} placeholder={t('select-source-placeholder')} initValue={sectionName === 'existingLead' && !values[sectionName].source_id ? 'keep' : values[sectionName].source_id} options={sourceOptions} onChange={(select) => setFieldValue(`${sectionName}.source_id`,sectionName === 'existingLead' && select.value === 'keep' ? null : select.value)} isError={touched[sectionName] && errors[sectionName] && touched[sectionName].source_id && errors[sectionName].source_id} menuShouldScrollIntoView={false} dontSort={true}/>
                    </FormInput>
                </VStack>
            </VStack>
        )
    }

    return (
        <VStack space={'1.05rem'}>
            {values?.newLead && getSection('newLead')}
            {values?.existingLead && getSection('existingLead')}
        </VStack>
    );
};

export default SiteTrialSettings;