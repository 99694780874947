import * as React from "react"
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

const IconArboxLogo = (props) => (
    <Svg
        width="58"
        height="13"
        fill={props.color}
        viewBox="0 0 58 13"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <G clipPath="url(#a)">
        <Path d="m3.273 6.295 1.23-3.852h.012l1.203 3.852H3.273ZM5.648.27H3.43L0 9.958h2.072l.68-1.999h3.545l.609 1.999h2.129L5.648.27ZM14.506 9.958V2.862h1.744v.853c.129-.212.29-.402.48-.562a1.962 1.962 0 0 1 1.05-.444c.185-.02.371-.029.558-.029h.243v1.915a3.03 3.03 0 0 0-.515-.044c-1.134 0-1.701.572-1.701 1.716v3.69h-1.86ZM29.323 8.019c-.296.399-.677.598-1.144.598-.448 0-.822-.187-1.122-.562-.3-.375-.451-.866-.451-1.473 0-1.537.52-2.306 1.559-2.306 1.067 0 1.6.73 1.601 2.19 0 .635-.148 1.152-.443 1.55v.003ZM31.46 4.86a3.502 3.502 0 0 0-.564-1.16 2.563 2.563 0 0 0-.909-.754 2.704 2.704 0 0 0-1.222-.267c-.867 0-1.563.37-2.088 1.11V.27h-1.83v9.688h1.787l.015-.865c.429.702 1.082 1.052 1.959 1.052a2.9 2.9 0 0 0 1.223-.26 2.67 2.67 0 0 0 .964-.75 3.65 3.65 0 0 0 .63-1.195c.159-.519.236-1.059.228-1.601.006-.5-.06-.997-.193-1.478ZM40.794 8.571c-1.134 0-1.7-.72-1.7-2.162 0-1.432.566-2.148 1.7-2.149 1.135 0 1.702.717 1.702 2.151 0 1.442-.568 2.163-1.702 2.163V8.57Zm3.325-3.702a3.168 3.168 0 0 0-.708-1.166 3.137 3.137 0 0 0-1.13-.756 3.922 3.922 0 0 0-1.486-.267 4.021 4.021 0 0 0-1.51.267 3.22 3.22 0 0 0-1.135.757c-.322.338-.568.74-.723 1.182a4.457 4.457 0 0 0-.25 1.529 4.538 4.538 0 0 0 .243 1.513c.147.442.388.845.707 1.181.319.337.707.598 1.138.765a4.02 4.02 0 0 0 1.527.275 3.934 3.934 0 0 0 1.48-.268 3.145 3.145 0 0 0 1.129-.756c.316-.338.56-.738.714-1.175a4.455 4.455 0 0 0 .25-1.536 4.515 4.515 0 0 0-.25-1.54M52.65 4.243l-.15.231-.89 1.384-1.94-2.994h2.09l.89 1.38ZM56.343 9.958h-2.077l-.97-1.495 1.049-1.598 1.998 3.093ZM58 .27 54.01 6.35 52.963 7.95l-3.01 4.59h-2.052l4.017-6.128.025-.037 1.024-1.591.017-.025L55.904.27h2.095Z" />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" transform="translate(0 .27)" d="M0 0h58v12.269H0z" />
        </ClipPath>
      </Defs>
    </Svg>
)

export default IconArboxLogo;
