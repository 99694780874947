import React, {useEffect, useMemo, useState} from 'react';
import {HStack, VStack, Text as NBText} from "native-base";
import FoldableSettingSection from "../../../../../common/FoldableSettingSection";
import {t} from "../../../../../../services/i18n";
import AppText from "../../../../../common/AppText";
import {GlobalStyleAttributes} from "../../../../../../styles/GlobalStyles";
import {Colors} from "../../../../../../styles/Colors";
import SimpleInput from "../../../../../common/SimpleInput";
import LiveLinkSetting from "../ReusableComponents/LiveLinkSetting";
import {useSelector} from "react-redux";
import {SERIES_FUTURE_SESSIONS_EDIT_TYPE, TRIAL} from "../../../../../../services/ConstHelper";
import {optionalSteps} from "../StepOptions";
import {sendDataEvent} from "../../../../../../services/Helpers";
import {Radio} from "antd";
import InfoTooltip from "../../../../../common/InfoTooltip";
import usePermissions from '../../../../../../services/Permissions';
import FormInput from '../../../../../common/FormInput';
import MultiSingleMenuWithSearch from '../../../../../common/MultiSingleMenuWithSearch';

const SessionSettings = (props) => {
    const { values, setFieldValue, errors, displayProps, target } = props;
    const editType = useSelector(state => state.stepper.editType)
    const selectionsTree = useSelector((state) => state.stepper.selectionsTree);
    const [openedSection, setOpenedSection] = useState(displayProps?.defaultOpenedSection || 'transparentSetting');
    const box = useSelector((state) => state.box.box);
    const selectedLocation = useMemo(() => {
        if(box && values.locations_box_fk) {
            return box.locations_box.find(l => l.id === values.locations_box_fk)
        }
    },[box, values.locations_box_fk])
    const taxes = useMemo(() => {
        if (selectedLocation && selectedLocation.taxes.length > 0) {
            return selectedLocation.taxes.reduce((acc, tax) => [...acc, {id: tax.id, name: `${tax.name} (${tax.percentage}%)`}],[])
        }
    },[selectedLocation])
    const currencySymbol = useMemo(() => selectedLocation?.currency_symbol,[selectedLocation])
    const allowOnlyDebt = useMemo(() => selectedLocation?.hasPayments === false, [selectedLocation]);

    const { has, hasLock } = usePermissions();

    const cancellationExplainText = useMemo(() => {
        if(values?.disable_cancellation_time) {
            if(values?.enable_late_cancellation) return t('cancellation-allow-late', {hoursNum: values?.disable_cancellation_time})
             else return t('cancellation-up-to', {hoursNum: values?.disable_cancellation_time})
        } else {
            return t('cancellation-always')
        }
    }, [values?.disable_cancellation_time, values?.enable_late_cancellation]);

    const cancellationValueRadio = useMemo(() => {
        if(values?.disable_cancellation_time) {
            if(values?.enable_late_cancellation) return 'with-late-cancellation'
            else return 'up-to'
        } else {
            return 'always'
        }
    }, [values?.disable_cancellation_time, values?.enable_late_cancellation]);

    useEffect(() => {
        if(selectionsTree[optionalSteps.EVENT_TYPE]) {
            sendDataEvent(`add ${selectionsTree[optionalSteps.EVENT_TYPE]} pop up - Settings`);
        }
    }, [selectionsTree[optionalSteps.EVENT_TYPE]]);

    useEffect(() => {
        console.log('values:',values)
        console.log('errors:',errors)
    }, [values, errors]);

    const onSectionToggle = (sectionName) => {
        if(openedSection === sectionName) setOpenedSection(null)
        else setOpenedSection(sectionName)
    }

    const onCancellationRadioSelect = (e) => {
        const selection = e.target.value
        switch (selection) {
            case 'always':
                setFieldValue('disable_cancellation_time', 0)
                setFieldValue('enable_late_cancellation', false)
                break;
            case 'up-to':
                setFieldValue('disable_cancellation_time', 1)
                setFieldValue('enable_late_cancellation', false)
                break;
            case 'with-late-cancellation':
                setFieldValue('disable_cancellation_time', 1)
                setFieldValue('enable_late_cancellation', true)
                break;
            default:
                break;
        }
    }

    const onMinimumUsersRadioSelection = (e) => {
        const selection = e.target.value
        if(selection === 'no-minimum') {
            setFieldValue('cancel_limit', 0)
            setFieldValue('min_users', 0)
        } else {
            setFieldValue('cancel_limit', 1)
            setFieldValue('min_users', 1)
        }
    }

    const onOnlineLinkRadioSelection = (selection) => {
        if(selection === 'in-person') setFieldValue('live_link', null)
        else setFieldValue('live_link', '')
    }

    const setValue = (name, value, shouldParseInt = false) => {
        setFieldValue(name, shouldParseInt ? (isNaN(parseInt(value)) ? '' : parseInt(value)) : value)
    }

    const getSeriesNameAttribute = () => {
        if(values?.repeatConfig && 'seriesName' in values.repeatConfig) return values.repeatConfig.seriesName
        if(values?.multiDayConfig && 'seriesName' in values.multiDayConfig) return values.multiDayConfig.seriesName
        if(target?.series && editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE) return values.series_name
    }

    const getSeriesNameAttributeName = () => {
        if(values?.repeatConfig && 'seriesName' in values.repeatConfig) return 'repeatConfig.seriesName'
        if(values?.multiDayConfig && 'seriesName' in values.multiDayConfig) return 'multiDayConfig.seriesName'
        if(target?.series && editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE) return 'series_name'
    }

    const handleTrialClassOption = (option) => {
        switch (option) {
            case 'no-trial-class':
                setFieldValue('paymentOptions', [])
                break;
            case 'free-trial-class':
            case 'paid-trial-class':
                setFieldValue('paymentOptions', [{type: TRIAL, price: 0, show_in_app: 1, max_installments: 1,
                    paid: option === 'paid-trial-class', taxes: []}])
                break;
            default:
                break;
        }
    }

    return (
        <VStack space={'0.5rem'}>
            {displayProps?.showTransparentSettings &&
            <FoldableSettingSection header={t('private-or-public')}
                                    isOpen={openedSection === 'transparentSetting'}
                                    onToggle={() => onSectionToggle('transparentSetting')}
                                    summary={t(`transparent-${!!values.transparent}`)}
            >
                <VStack space={'1rem'}>
                    <Radio.Group name={'transparentSetting-radio'}
                                 onChange={e => setFieldValue('transparent', e.target.value === 'transparent')}
                                 value={values.transparent ? 'transparent' : 'not-transparent'}>
                        <VStack space={'1rem'}>
                            <Radio value="not-transparent" size="sm"><AppText
                                fontSize={GlobalStyleAttributes.fonts.heading3}
                                color={Colors.newDesign.mainText}>{t('transparent-radio-false')}</AppText></Radio>
                            <Radio value="transparent" size="sm"><AppText
                                fontSize={GlobalStyleAttributes.fonts.heading3}
                                color={Colors.newDesign.mainText}>{t('transparent-radio-true')}</AppText></Radio>
                        </VStack>
                    </Radio.Group>
                </VStack>
            </FoldableSettingSection>
            }
            {displayProps?.showSpots && has('hasSpots') &&
                <FoldableSettingSection header={t('class-has-spots')}
                                        isOpen={openedSection === 'spotSettings'}
                                        onToggle={() => onSectionToggle('spotSettings')}
                                        summary={values.has_spots ? t('has-spots-description-true') : t('has-spots-description-false')}
                >
                    <VStack space={'1rem'}>
                        <Radio.Group name={'spotSettings-radio'}
                                     onChange={e => {
                                         const selectedValue = parseInt(e.target.value); // Convert string to integer
                                         setFieldValue('has_spots', selectedValue === 1); // Update based on the numeric value
                                     }}
                                     value={values.has_spots ? 1 : 0}>
                            <VStack space={'1rem'}>
                                <Radio value={0} size="sm"><AppText
                                    fontSize={GlobalStyleAttributes.fonts.heading3}
                                    color={Colors.newDesign.mainText}>{t('has-spots-radio-false')}</AppText></Radio>
                                <Radio value={1} size="sm"><AppText
                                    fontSize={GlobalStyleAttributes.fonts.heading3}
                                    color={Colors.newDesign.mainText}>{t('has-spots-radio-true')}</AppText></Radio>
                            </VStack>
                        </Radio.Group>
                    </VStack>
                </FoldableSettingSection>
            }
            {displayProps?.showBookForTrialSettings && has('bookForTrialClassSite') &&
            <FoldableSettingSection header={t('enable-trial-class-on-site')} infoTooltip={t(`enable-trial-class-on-site-tooltip${editType ? '-edit' : ''}`)}
                    isOpen={openedSection === 'trialClassSiteSetting'} onToggle={() => onSectionToggle('trialClassSiteSetting')}
                    hasLock={hasLock('bookForTrialClassSite')} summary={t(`explain-trial-class-on-site-${values?.paymentOptions?.[0] ? (values?.paymentOptions?.[0]?.paid ? `paid${allowOnlyDebt ? '-debt' : ''}` : 'free') : 'not-allowed'}${editType ? '-edit' : ''}`, {price: `${values?.paymentOptions?.[0]?.price || 0}${currencySymbol}`})}>
                <VStack space={'1rem'}>
                    <Radio.Group name={'trialClassSiteSetting-radio'}
                                 onChange={e => handleTrialClassOption(e.target.value)}
                                 value={!values.paymentOptions?.[0] ? 'no-trial-class' : (values.paymentOptions?.[0]?.paid ? 'paid-trial-class' : 'free-trial-class')}>
                        <VStack space={'1rem'}>
                            <Radio value="no-trial-class" size="sm"><AppText
                                fontSize={GlobalStyleAttributes.fonts.heading3}
                                color={Colors.newDesign.mainText}>{t('no-trial-class-site')}</AppText></Radio>
                            <Radio value="free-trial-class" size="sm"><AppText
                                fontSize={GlobalStyleAttributes.fonts.heading3}
                                color={Colors.newDesign.mainText}>{t('free-trial-class-site')}</AppText></Radio>
                            <VStack space={'0.5rem'}>
                                <Radio value="paid-trial-class" size="sm">
                                    <HStack><AppText
                                    fontSize={GlobalStyleAttributes.fonts.heading3}
                                    color={Colors.newDesign.mainText}>{t(`paid-trial-class-site${allowOnlyDebt ? '-debt' : ''}`)}
                                    </AppText>
                                    </HStack>
                                </Radio>
                                <VStack marginX={'1.5rem'}>
                                    {values?.paymentOptions?.[0]?.paid && <FormInput
                                        label={taxes ? t('price-per-person') : null}
                                        width={'5rem'}
                                        value={values?.paymentOptions?.[0]?.price}
                                        formatAddon={currencySymbol}
                                        containerProps={{mb: 0, p: 0}}
                                        styleOverride={{paddingInline: '0.3rem'}}
                                        inputType={'float'}
                                        errorHeight={'unset'}
                                        onChangeCallback={(val) => setFieldValue('paymentOptions[0].price', val)}/>}
                                    {taxes && values?.paymentOptions?.[0]?.paid && <VStack space={'0.2rem'} w={'12rem'}>
                                        <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('taxes')}</AppText>
                                        <MultiSingleMenuWithSearch options={taxes} onChange={(val) => setFieldValue('paymentOptions[0].taxes', val)} placeholder={t('taxSelectorLabel')} defaultValue={values?.paymentOptions?.[0]?.taxes}/>
                                    </VStack>}
                                </VStack>
                            </VStack>
                        </VStack>
                    </Radio.Group>
                </VStack>
            </FoldableSettingSection>}
            {displayProps?.showCancellationSettings &&
            <FoldableSettingSection header={t('cancellation')}
                                    isOpen={openedSection === 'cancellationSetting'}
                                    onToggle={() => onSectionToggle('cancellationSetting')}
                                    summary={cancellationExplainText}
            >
                <VStack space={'1rem'}>
                    <Radio.Group name={'cancellationSetting-radio'} onChange={onCancellationRadioSelect} value={cancellationValueRadio}>
                        <VStack space={'1rem'}>
                            <Radio value="always" size="sm">
                                <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('cancellation-radio-allow-always')}</AppText>
                            </Radio>
                            <Radio value="up-to" size="sm">
                                <NBText style={{direction: 'inherit'}}>
                                    <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('cancellation-radio-allow-up-to')}</AppText>
                                    <SimpleInput value={cancellationValueRadio === 'up-to' ? values.disable_cancellation_time : 0}
                                                 onChangeCallback={(val) => setValue('disable_cancellation_time', val, false)}
                                                 inputType={'float'}
                                                 width={'4rem'}
                                                 styleOverride={{margin: '0 0.5rem'}}
                                    />
                                    <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('before-session')}</AppText>
                                </NBText>
                            </Radio>
                            <Radio value="with-late-cancellation" size="sm" >
                                <HStack alignItems={'center'}>
                                    <NBText style={{direction: 'inherit'}}>
                                        <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('cancellation-radio-allow-late')}</AppText>
                                        <SimpleInput value={cancellationValueRadio === 'with-late-cancellation' ? values.disable_cancellation_time : 0}
                                                     onChangeCallback={(val) => setValue('disable_cancellation_time', val, false)}
                                                     inputType={'float'}
                                                     width={'4rem'}
                                                     styleOverride={{margin: '0 0.5rem'}}
                                        />
                                        <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('before-session')}</AppText>
                                    </NBText>
                                    <InfoTooltip title={t('late-cancellation-tooltip')}/>
                                </HStack>
                            </Radio>
                        </VStack>
                    </Radio.Group>
                </VStack>
            </FoldableSettingSection>
            }
            {displayProps?.showRegistrationRestrictionsSettings &&
            <FoldableSettingSection header={t('registration-restrictions')}
                                    isOpen={openedSection === 'registrationSetting'}
                                    onToggle={() => onSectionToggle('registrationSetting')}
                                    summary={t(values.enable_registration_time ? 'registration-restrictions-open' : 'no-registration-restrictions', {hoursNum: values.enable_registration_time})}
            >
                <VStack space={'1rem'}>
                    <Radio.Group name={'registrationSetting-radio'}
                                 onChange={e => setFieldValue('enable_registration_time', e.target.value === 'no-restrictions' ? 0 : 1)}
                                 value={values.enable_registration_time ? 'restrict-registration' : 'no-restrictions'}>
                        <VStack space={'1rem'}>
                            <Radio value="no-restrictions" size="sm"><AppText
                                fontSize={GlobalStyleAttributes.fonts.heading3}
                                color={Colors.newDesign.mainText}>{t('no-registration-restrictions-radio')}</AppText></Radio>
                            <Radio value="restrict-registration" size="sm">
                                <NBText style={{direction: 'inherit'}}>
                                    <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('registration-restrictions-radio-open')}</AppText>
                                    <SimpleInput value={values.enable_registration_time}
                                                 onChangeCallback={(val) => setValue('enable_registration_time', val, false)}
                                                 width={'4rem'}
                                                 inputType={'float'}
                                                 styleOverride={{margin: '0 0.5rem'}}
                                    />
                                    <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('before-it-begins')}</AppText>
                                </NBText>
                            </Radio>
                        </VStack>
                    </Radio.Group>
                </VStack>
            </FoldableSettingSection>
            }
            {displayProps?.showRegistrationBlockSettings &&
            <FoldableSettingSection header={t('registration-block-time')}
                                    isOpen={openedSection === 'registrationBlockSetting'}
                                    onToggle={() => onSectionToggle('registrationBlockSetting')}
                                    summary={t(values.block_registration_time ? 'registration-time-block-open' : 'no-registration-time-block', {hoursNum: values.block_registration_time})}
                                    error={errors.block_registration_time ? t(errors.block_registration_time) : null}
            >
                <VStack space={'1rem'}>
                    <Radio.Group name={'registrationBlockSetting-radio'}
                                 onChange={e => setFieldValue('block_registration_time', e.target.value === 'no-registration-time-block' ? 0 : 1)}
                                 value={values.block_registration_time ? 'registration-time-block' : 'no-registration-time-block'}>
                            <VStack space={'1rem'}>
                                <Radio value='no-registration-time-block' size="sm"><AppText
                                    fontSize={GlobalStyleAttributes.fonts.heading3}
                                    color={Colors.newDesign.mainText}>{t('no-registration-time-block-radio')}</AppText></Radio>
                                <Radio value="registration-time-block" size="sm">
                                    <NBText style={{direction: 'inherit'}}>
                                        <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('registration-time-block-radio-open')}</AppText>
                                        <SimpleInput value={values.block_registration_time}
                                                     onChangeCallback={(val) => setValue('block_registration_time', val, false)}
                                                     inputType={'float'}
                                                     width={'4rem'}
                                                     styleOverride={{margin: '0 0.5rem'}}
                                        />
                                        <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('before-it-begins')}</AppText>
                                    </NBText>
                                </Radio>
                            </VStack>
                        </Radio.Group>
                </VStack>
            </FoldableSettingSection>


            }
            {displayProps?.showMinimumParticipantsSettings &&
            <FoldableSettingSection header={t('minimumParticipants')}
                                    isOpen={openedSection === 'minimumParticipantsSetting'}
                                    onToggle={() => onSectionToggle('minimumParticipantsSetting')}
                                    summary={t(!values.cancel_limit ? 'no-minimum' : 'minimum-restriction-open')}
            >
                <VStack space={'1rem'}>
                    <Radio.Group name={'minimumParticipantsSetting-radio'} onChange={onMinimumUsersRadioSelection} value={values.min_users && values.cancel_limit ? 'restrict-minimum' : 'no-minimum'}>
                        <VStack space={'1rem'}>
                            <Radio value="no-minimum" size="sm">
                                <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('no-minimum-radio')}</AppText></Radio>
                            <Radio value="restrict-minimum" size="sm">
                                <VStack flex={1}>
                                    <HStack space={'0.5rem'} alignItems={'center'}>
                                        <NBText style={{direction: 'inherit'}}>
                                            <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('minimum-restriction-open-radio')}</AppText>
                                            <SimpleInput value={values.min_users}
                                                         onChangeCallback={(val) => setValue('min_users', val, true)}
                                                         numericOnly={true}
                                                         width={'4rem'}
                                                         styleOverride={{margin: '0 0.5rem'}}
                                            />
                                        </NBText>

                                    </HStack>
                                    <HStack space={'0.5rem'} alignItems={'center'}>
                                        <NBText style={{direction: 'inherit'}}>
                                            <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('upTo')}</AppText>
                                            <SimpleInput value={values.cancel_limit}
                                                         onChangeCallback={(val) => setValue('cancel_limit', val, true)}
                                                         numericOnly={true}
                                                         width={'4rem'}
                                                         styleOverride={{margin: '0 0.5rem'}}
                                            />
                                            <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t('before-session')}</AppText>
                                        </NBText>
                                    </HStack>
                                </VStack>
                            </Radio>
                        </VStack>
                    </Radio.Group>
                </VStack>
            </FoldableSettingSection>
            }
            {displayProps?.showSeriesNameSettings &&
                <FoldableSettingSection header={t(`${selectionsTree[optionalSteps.EVENT_TYPE] ?? 'class'}SeriesName`)}
                                        isOpen={openedSection === 'seriesNameSetting'}
                                        onToggle={() => onSectionToggle('seriesNameSetting')}
                                        summary={t(`series-name-${selectionsTree[optionalSteps.EVENT_TYPE] ? `${selectionsTree[optionalSteps.EVENT_TYPE]}-` : ''}${getSeriesNameAttribute() ? '' : 'dont-'}exist`)}
                >
                    <VStack>
                        <AppText fontSize={GlobalStyleAttributes.fonts.heading3} color={Colors.newDesign.mainText}>{t(`series-name-explain-${selectionsTree[optionalSteps.EVENT_TYPE] ?? 'class'}`)}</AppText>
                        <SimpleInput value={getSeriesNameAttribute()}
                                     onChangeCallback={(val) => setValue(getSeriesNameAttributeName(), val)}
                                     autoFocus
                        />
                    </VStack>
                </FoldableSettingSection>
            }
            {displayProps?.showGroupRegisterSettings &&
            <FoldableSettingSection header={t('groupRegisterSetting')}
                                    isOpen={openedSection === 'groupRegisterSetting'}
                                    onToggle={() => onSectionToggle('groupRegisterSetting')}
                                    summary={t(`groupRegister-${values.register_group_member ? 'on' : 'off'}`)}
            >
                <VStack space={'1rem'}>
                    <Radio.Group name={'groupRegisterSetting-radio'}
                                 onChange={e => setFieldValue('register_group_member', e.target.value === 'allow-group')}
                                 value={values.register_group_member ? 'allow-group' : 'dont-allow-group'}>
                        <VStack space={'1rem'}>
                            <Radio value="dont-allow-group" size="sm"><AppText
                                fontSize={GlobalStyleAttributes.fonts.heading3}
                                color={Colors.newDesign.mainText}>{t('groupRegister-off-radio')}</AppText></Radio>
                            <Radio value="allow-group" size="sm"><AppText
                                fontSize={GlobalStyleAttributes.fonts.heading3}
                                color={Colors.newDesign.mainText}>{t('groupRegister-on-radio')}</AppText></Radio>
                        </VStack>
                    </Radio.Group>
                </VStack>
            </FoldableSettingSection>
            }
            {displayProps?.showMidBookingSettings &&
                <FoldableSettingSection header={t('hugMidBookingSettingHeader')}
                                        isOpen={openedSection === 'midBookingSettings'}
                                        onToggle={() => onSectionToggle('midBookingSettings')}
                                        summary={t(`midBooking-${values.allow_mid_booking ? 'on' : 'off'}`)}
                >
                    <VStack space={'1rem'}>
                        <Radio.Group name={'idBookingSettings-radio'}
                                     onChange={e => setFieldValue('allow_mid_booking', e.target.value === 'allow-mid-booking')}
                                     value={values.allow_mid_booking ? 'allow-mid-booking' : 'dont-allow-mid-booking'}>
                            <VStack space={'1rem'}>
                                <Radio value="dont-allow-mid-booking" size="sm"><AppText
                                    fontSize={GlobalStyleAttributes.fonts.heading3}
                                    color={Colors.newDesign.mainText}>{t('mid-booking-off-radio')}</AppText></Radio>
                                <Radio value="allow-mid-booking" size="sm"><AppText
                                    fontSize={GlobalStyleAttributes.fonts.heading3}
                                    color={Colors.newDesign.mainText}>{t('mid-booking-on-radio')}</AppText></Radio>
                            </VStack>
                        </Radio.Group>
                    </VStack>
                </FoldableSettingSection>
            }
            {displayProps?.showOnlineSessionSettings &&
            <FoldableSettingSection header={t(`online-${selectionsTree[optionalSteps.EVENT_TYPE] ?? 'session'}`)}
                                    isOpen={openedSection === 'onlineSetting'}
                                    onToggle={() => onSectionToggle('onlineSetting')}
                                    summary={t(values.live_link ? `online-${selectionsTree[optionalSteps.EVENT_TYPE] ?? 'session'}-open` : `personal-${selectionsTree[optionalSteps.EVENT_TYPE] ?? 'session'}-radio`)}
            >
                <VStack space={'1rem'}>
                    <LiveLinkSetting selectionValue={values.live_link === null ? 'in-person' : 'online'}
                                     inputValue={values.live_link} onChangeSelection={onOnlineLinkRadioSelection}
                                     onChangeText={(val) => setValue('live_link', val)}
                    />
                </VStack>
            </FoldableSettingSection>
            }
        </VStack>
    );
};

export default SessionSettings;
