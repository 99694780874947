import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../../../services/i18n';
import FormInput from '../../../common/FormInput';
import AdvanceSelect from '../../../common/AdvanceSelect';
import { familyArray, USERS_SELECT } from '../../../../services/ConstHelper';
import MemberDetails from '../MemberDetails';
import AppText from '../../../common/AppText';
import { Divider, Flex } from 'antd';
import { Colors } from '../../../../styles/Colors';
import { GlobalStyleAttributes } from '../../../../styles/GlobalStyles';
import { useEffect } from 'react';
import CustomCheckbox from '../../../common/CustomCheckbox';
import InfoTooltip from '../../../common/InfoTooltip';
import CustomButtonV2 from '../../../common/CustomButtonV2';

const GroupConnectionAddUser = ({ values, setFieldValue, touched, errors, handleChange, validationSchema, setTouched, target, validateField, ...rest }) => {
    const box = useSelector(state => state.box?.box);
    const [selectedUser, setSelectedUser] = useState(null);

    const relationOptions = useMemo(() =>  familyArray.map(index => ({ value: index, label: t(`child${index}`) })), []);
    const currentMembers = useMemo(() => target.groupData.map(member => member.user_box) || [], [target.groupData]);

    const selectUser = (user) => {
        setSelectedUser(user);
        setFieldValue('users_boxes_id', user?.users_boxes_id);
        setFieldValue('delete_ugc_id', null);
        if (user?.group_members?.length > 1) {
            setFieldValue('ugc_id', user?.ugc_id);
        } else {
            setFieldValue('ugc_id', null);
            if (user?.ugc_id) {
                setFieldValue('delete_ugc_id', user?.ugc_id);
            }
        }
        setTimeout(() => {validateField('users_boxes_id')}, 300)
        
    };

    useEffect(() => {
        setTouched({})
    }, [])


    return (
        <Flex vertical>
            {/* User Search */}
            {values.selection === 'existing' && <FormInput
                required
                label={t('groups-add-member')}
                width="100%"
                error={touched.users_boxes_id && errors.users_boxes_id}
                helper={values.delete_ugc_id && t('group-with-only-one-member-warning')}
            >
                <AdvanceSelect
                    placeholder={t('groups-search-member')}
                    initValue={selectedUser}
                    type={USERS_SELECT}
                    onChange={selectUser}
                    hideLeadType
                    hideFreefitType
                    hideCustomerId
                    allowInactive
                    withGroups
                    isClearable={true}
                    isError={touched.users_boxes_id && errors.users_boxes_id}
                />
            </FormInput>}

            {/* Current Members List */}
            {currentMembers.length > 0 && (
                <FormInput label={t('groups-current-members')} containerProps={{ space: '0.5rem' }}>
                <Flex vertical gap={'0.5rem'}>
                    {currentMembers.map((member, index) => (
                        <Flex
                            key={member.id || index}
                        >
                            <Flex vertical justify="space-between" align="center">
                                <Flex vertical gap={'0.1rem'}>
                                <CustomButtonV2 onClick={() => window.open(`/user-profile/${member.user_fk}`, '_blank')}
                                                    iconProps={{iconColor: Colors.newDesign.mainText, iconHoverColor: Colors.newDesign.mainBlueHover}}
                                                    override={({color}) => (
                                                    <AppText color={color}>
                                                        {`${member.first_name} ${member.last_name} - ${member.head ? t('head') : t(`child${member.child}`)}`}
                                                    </AppText>)}
                                    />
                                    <AppText color={Colors.newDesign.darkGrey} fontSize={GlobalStyleAttributes.fonts.infoText}>
                                        {`${member.age ? `${member.age}, ` : ''}${t(member.gender)}, ${member.active ? t('active') : t('inactive')}`}
                                    </AppText>
                                </Flex>
                            </Flex>
                        </Flex>
                    ))}
                </Flex>
                </FormInput>
            )}

            {/* Member Relation */}
            <FormInput
                label={t('groups-new-member-relation')}
                width="100%"
                required
                error={touched.child && errors.child}
                containerProps={{ mb: '10px' }}
            >
                <AdvanceSelect
                    options={relationOptions}
                    initValue={values.child}
                    onChange={(option) => setFieldValue('child', option.value)}
                    placeholder={t('groups-select-relation')}
                    isError={touched.child && errors.child}
                />
            </FormInput>
            <Flex>
                <CustomCheckbox text={t('define-as-head')} isChecked={values.head} onChange={(val) => setFieldValue('head', val)} />
                {/* <InfoTooltip title={t('define-as-head-tooltip')} /> */}
            </Flex>
            {values.selection === 'new' && <Divider style={{marginBlock: '15px'}} />}
            {values.selection === 'new' && <MemberDetails 
                  touched={touched.newUser || {}} errors={errors.newUser || {}}  values={values.newUser || {}} 
                  setFieldValue={(field, value) => setFieldValue(`newUser.${field}`, value)} 
                  handleChange={(field) => handleChange(`newUser.${field}`)} 
                  validationSchema={validationSchema.fields.newUser}
                  {...rest}  />}
        </Flex>
    );
};

export default GroupConnectionAddUser;
