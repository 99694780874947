import React from 'react';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { t } from '../../../../services/i18n';
import { updateFlags } from '../../../../redux/stepperSlice';
import {Flex, Radio} from "antd";
import CustomButtonV2 from '../../../common/CustomButtonV2';
import { Colors } from '../../../../styles/Colors';
import { GlobalStyleAttributes } from '../../../../styles/GlobalStyles';
import AppText from '../../../common/AppText';

const ChooseGroupConnectionUser = ({setFieldValue, values}) => {
    const types = useMemo(() => [
        {id: 'existing', name: t('existing-user')},
        {id: 'new', name: t('new-user')}
    ],[]);
    const dispatch = useDispatch()

    useEffect(() => {
            dispatch(updateFlags({selection: values.selection}))
    }, [values.selection]);

    return (
        <Radio.Group name={'ufc-options-radio'} value={values.selection}>
            <Flex vertical gap={'1rem'}>
                {
                    types.map(type => (
                            <CustomButtonV2 onClick={() => setFieldValue('selection', type.id)}
                                            key={`gc-option-${type.id}`}
                                            iconProps={{iconColor: Colors.borderColor, iconHoverColor: Colors.hoverBlueLight}}
                                            override={({color}) =>
                                                (
                                                    <Flex gap={'0.5rem'} style={{width: '100%', padding: '1rem', borderStyle: 'solid', borderWidth: 1, borderColor: values.selection === type.id ? Colors.newDesign.mainBlue : color, borderRadius: 4}}>
                                                        <Radio value={type.id} size="sm"/>
                                                        <Flex direction={'column'} flex={1}>
                                                            <AppText fontSize={GlobalStyleAttributes.fonts.heading3} boldFont>{type.name}</AppText>
                                                        </Flex>
                                                    </Flex>
                                                )}
                            />
                        )
                    )
                }
            </Flex>
        </Radio.Group>
    );
};

export default ChooseGroupConnectionUser;
