import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import moment from 'moment';
import { Box, Button, HStack, ScrollView, Spinner, Stack } from 'native-base';
import React, { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useToast } from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { SMS_DISPLAY_VERIFY } from '../../../Configs/SettingsValueModalOptions';
import {openModal, SETTINGS_VALUE_MODAL, STEPPER_CONFIRM_MODAL} from '../../../redux/modalManagerSlice';
import { hideLoader, showLoader } from '../../../redux/rootManagerSlice';
import { clearError, setError } from '../../../redux/stepperSlice';
import { EMAIL, MESSAGE_TEMPLATE_SELECT, PUSH, SMS, USERS_SELECT, WHATSAPP } from '../../../services/ConstHelper';
import {getLocale, isActive, isMobile, messageAudienceByType} from '../../../services/Helpers';
import { fetchApi } from '../../../services/HTTP';
import { t } from '../../../services/i18n';
import { Colors } from '../../../styles/Colors';
import { GlobalConsts } from '../../../styles/GlobalConsts';
import { GlobalStyleAttributes } from '../../../styles/GlobalStyles';
import AdvanceSelect from '../../common/AdvanceSelect';
import AppText from '../../common/AppText';
import ConfirmModal from '../../common/ConfirmModal';
import FormInput from '../../common/FormInput';
import SimpleInput from '../../common/SimpleInput';
import { sendMessageFetch } from '../Schedule/Popups/CreateEvent/FlowOptions';
import usePermissions from '../../../services/Permissions';
import CustomCheckbox from "../../common/CustomCheckbox";
import CustomButtonV2 from "../../common/CustomButtonV2";
import { Flex } from 'antd';


const MessageDetails = props => {
    const { values, setFieldValue, touched, errors, handleChange, target, handleSubmit, ...rest } = props;
    const box = useSelector(state => state.box?.box)
    const currentUser = useSelector(state => state.user?.user)
    const stepperError = useSelector(state => state.stepper?.error)
    const stepperProps = useSelector(state => state.stepper?.props)
    const [user, setUser] = useState(null);
    const [show, setShow] = useState(false);
    const [smsBalance, setSmsBalance] = useState(null);
    const [showMemberlist, setShowMemberlist] = useState(false);
    const [showVerifyNotification, setShowVerifyNotification] = useState(null);
    const [confirmSend, setConfirmSend] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [selectedUsers, setSelectedUsers] = useState(target?.selectedUsers)
    const isIL = box?.locations_box[0]?.country_code === 'IL';
    const targetUser = stepperProps?.target || target
    const usersType = { booked: 'send-message-booked', cancelled: 'send-message-cancelled', stand_by: 'send-message-standby' }[targetUser?.activeTab]
    const targetOptions = useMemo(() => (messageAudienceByType[values.type || targetUser.selectedType] || []).map(audience => ({ value: audience, label: t(audience) })), [values.type])
    const locationOptions = useMemo(() => [{ value: -1, label: t('allLocations') }, ...box?.locations_box?.map(location => ({ value: location.id, label: location.location }))] || [box]);
    const timeFormat = useMemo(() => box ? box.locations_box[0].time_format : null, [box])
    const dateFormat = useMemo(() => box ? box.locations_box[0].date_format : null, [box])
    const dispatch = useDispatch()
    const toast = useToast()
    const { has } = usePermissions()
    const [messageTempaltes] = useState(box.message_template?.filter(isActive)?.map(template => ({...template, value: template.id, label: template.name, text: template.text.replace('{{#firstName#}}', `[${t('firstName')}]`).replace('{{#lastName#}}', `[${t('lastName')}]`)})))

    const tags = useMemo(() => {
        let tags = [
            { id: 'first_name', label: t('firstName') },
            { id: 'last_name', label: t('lastName') },
        ];
        if (targetUser?.type === 'schedule') {
            tags.push(...[
                { id: 'time', label: t('time') },
                { id: 'date', label: t('date') },
                { id: 'session_name', label: t('sessionName') }
            ])
        }
        if(targetUser?.type === 'reports' && targetUser?.messageParams) {
            tags.push(...target.messageParams)
        }
        return tags;
    }, []);

    const specificTarget = useMemo(() => {
        if (targetUser?.type === 'schedule') {
            const coachesArray = [...(targetUser.eventData.coach ? [targetUser.eventData.coach] : []), ...(targetUser.eventData.second_coach ? [targetUser.eventData.second_coach] : [])];
            return ({
                name: targetUser.eventData.title || targetUser.eventData?.box_categories?.name,
                users: [...coachesArray, ...targetUser.users].filter(u => values.type !== PUSH || u.type !== 'lead').map(u => ({ ...u, isSelected: true })),
                time: targetUser.eventData.time,
                date: targetUser.eventData.date,
                usersType: `${t('sessions-users', { name: targetUser.eventData.title || targetUser.eventData?.box_categories?.name, users: t(usersType) })}`
            })
        }
        if (targetUser?.type === 'reports') {
            return ({
                users: Object.values(selectedUsers).map(user => ({...user[0], isSelected: true})),
                usersType: t('reports-users')
            })
        }
        return null
    }, [targetUser, selectedUsers])

    useEffect(() => {
        if(specificTarget) {
            setFieldValue('specificTarget', specificTarget)
            setFieldValue('user', {first_name: specificTarget.first_name, last_name: specificTarget.last_name})
        }
    }, [specificTarget]);

    const charCount = values.messageContent?.length || 0;
    const maxCharCount = ({ [SMS]: isIL ? 133 : 70, [PUSH]: 175, [EMAIL]: 1500, [WHATSAPP]: 5000 })[values.type] || 175;

    useEffect(() => {
        console.log('messageContent',values.messageContent)
    }, [values.messageContent]);

    useEffect(() => {
        if (targetUser?.type === 'reports') {
            const updatedData = {}
            if(values?.type === PUSH) {
                Object.keys(targetUser.selectedUsers).forEach(userFk => {
                    updatedData[userFk] = targetUser.selectedUsers[userFk].filter(userBox => userBox.user_role_id !== 18 && userBox.full_name !== t('deletedUser') && !userBox.deleted_at_user)
                    if(updatedData[userFk].length === 0) {
                        delete updatedData[userFk]
                    }
                })
                setSelectedUsers(updatedData)
            } else {
                Object.keys(targetUser.selectedUsers).forEach(userFk => {
                    updatedData[userFk] = targetUser.selectedUsers[userFk].filter(userBox => userBox.full_name !== t('deletedUser') && !userBox.deleted_at_user)
                    if(updatedData[userFk].length === 0) {
                        delete updatedData[userFk]
                    }
                })
                setSelectedUsers(updatedData)
            }
        }
    }, [targetUser?.selectedUsers, targetUser?.type])


    const onTagClick = (label) => {
        if (label === t('date') && targetUser?.type !== 'reports') {
            setFieldValue('messageContent', `${values.messageContent || ''}${moment(specificTarget.date).format(dateFormat)}`)
        } else if (label === t('time') && targetUser?.type !== 'reports') {
            setFieldValue('messageContent', `${values.messageContent || ''}${moment(specificTarget.time, timeFormat).format(timeFormat)}`)
        } else if (label === t('sessionName') && targetUser?.type !== 'reports') {
            setFieldValue('messageContent', `${values.messageContent || ''}${specificTarget.name}`)
        } else if (`${values.messageContent || ''} [${label}]`.length <= maxCharCount) {
            setFieldValue('messageContent', `${values.messageContent || ''}[${label}]`)
        }
        document?.getElementById('text-area-message')?.focus()
    }

    const onChooseTemplate = (option) => {
        setSelectedTemplate(option.name)
        setFieldValue('digital_form_id', option.digital_form_id)
        setFieldValue('messageContent', option.text)
        if (values.type === EMAIL && option?.subject) {
            setFieldValue('subject', option.subject);
        }
    }

    const isSelected = (i) => values.users[i]?.isSelected

    const selectUser = (user, i) => {
        if (values.class_name === 'PrivateMessage') {
            setUser(user);
            setFieldValue('users', [{ ...user, isSelected: true }])
        } else {
            if (isSelected(i)) unselectUser(i)
            else setFieldValue(`users.${i}.isSelected`, true)
        }
    }

    const unselectUser = (i) => {
        setFieldValue(`users.${i}.isSelected`, false)
    }

    const sendConfirmationSms = async () => {
        try {
            setShowVerifyNotification(null)
            dispatch(showLoader());
            const res = await fetchApi('boxes/sendOTP', 'POST', { sender_id: values.senderID }, false, true);
            dispatch(hideLoader());
            switch (res) {
                case -3040: //Already verified
                    setFieldValue('verifiedSenderID', values.senderID);
                    toast.show(t('verified-success'), { type: 'success', duration: 2000 });
                    break;
                case 1: //otp has sent
                    dispatch(openModal({ modalName: SETTINGS_VALUE_MODAL, props: { type: SMS_DISPLAY_VERIFY, params: { sender_id: values.senderID }, onChange: setFieldValue } }))
                    break;
                default:
                    toast.show(t('send-otp-has-failed'), { type: 'danger', duration: 2000 });
                    break;
            }
        } catch (error) {
            dispatch(hideLoader());
            console.error(error)
            toast.show(t('send-otp-has-failed'), { type: 'danger', duration: 2000 });
        }
    }

    const openVerify = () => {
        setShowVerifyNotification({
            header: t('phone-number-confirmation'),
            subheader: t('send-confirmation-text-message-to-phone', { phone: values.senderID }),
            confirmText: t('confirm'),
            cancelText: t('cancel'),
            onConfirm: sendConfirmationSms,
            onCancel: () => setShowVerifyNotification(false),
        });
    }

    const onSendMePress = () => {
        if (values.type === SMS && (!currentUser.phone || currentUser.phone?.length <= 3)) {
            toast.show(t('user-without-phone-number'), { type: 'danger', duration: 2000 });
            return
        }
        if (!values.messageContent) {
            toast.show(t('message-is-empty-error'), { type: 'danger', duration: 2000 });
            return
        }
        if (values.type === EMAIL && !values.subject) {
            toast.show(t('email-without-subject'), { type: 'danger', duration: 2000 });
            return
        }
        setConfirmSend({
            header: t(`confirm-send-to-me-header-${values.type}`),
            subheader: t(`confirm-send-to-me-subheader-${values.type}`, { phone: currentUser.phone, email: currentUser.email, full_name: currentUser.full_name }),
            confirmText: t('confirm'),
            cancelText: t('cancel'),
            onConfirm: () => sendMeTest(),
            onCancel: () => setConfirmSend(null),
        });
    }

    const sendMeTest = async () => {
        setConfirmSend(null)
        try {
            const res = await sendMessageFetch({
                class_name: 'PrivateMessage',
                messageContent: values.messageContent,
                users: [{ ...currentUser }],
                senderID: values.verifiedSenderID,
                type: values.type,
                subject: values.subject,
                digital_form_id: values.digital_form_id
            }, dispatch)
            if (res) {
                toast.show(t('test-has-sent'), { type: 'success', duration: 2000 });
            }
        } catch (error) {
            dispatch(setError({message: 'sendMessageError', error}))
        }
    }

    const getSmsBalance = async () => {
        const res = await fetchApi('boxes/getSmsBalance', 'POST', null, false, true);
        setSmsBalance(res || 0);
    }

    useEffect(() => {
        if (!values.type) return;
        if (values.type === SMS) {
            getSmsBalance();
        }
        setFieldValue('messageContent', '')
        setFieldValue('subject', '')
        setFieldValue('locations_box_fk', currentUser.locations_box_fk)
        setFieldValue('class_name', '')
        setFieldValue('users', [])
        setFieldValue('confirm', false)
        setFieldValue('senderID', values.type === EMAIL ? box.email : box.sms_display_phone);
        setFieldValue('verifiedSenderID', values.type === EMAIL ? box.email : box.sms_display_phone);
        if (targetUser) {
            if (targetUser?.type === 'private') {
                setFieldValue('class_name', 'PrivateMessage');
                setUser(targetUser.user);
                setFieldValue('users', [{ ...targetUser.user, isSelected: true }])
            } else {
                setFieldValue('class_name', 'ReportMessage');
                setFieldValue('users', specificTarget?.users || []);
            }
        }
        setTimeout(() => setShow(true), 100);
    }, [values.type, specificTarget?.users]);

    useEffect(() => {
        if (values.verifiedSenderID !== values.senderID) {
            const needVerification = /^\d+$/.test(values.senderID)
            if (!needVerification || values.type === EMAIL) setFieldValue('verifiedSenderID', values.senderID)
        }
    }, [values.senderID])

    useEffect(() => {
        if (values.confirm) {
            setConfirmSend(null)
            handleSubmit()
            setTimeout(() => setFieldValue('confirm', false), 50);
        }
    }, [values.confirm])

    if (!show) return;


    return (
        <>
            {/* sms purchase */}
            {values.type === SMS && <Box style={[{ backgroundColor: Colors.newDesign.lightBlue, marginBottom: '1rem', borderRadius: 4 }]} padding={'1rem'} space={'0.5rem'}>
                <HStack alignItems={'center'} space={'0.5rem'}>
                    <FontAwesomeIcon icon={light('message-lines')} size={GlobalConsts.iconSize.XS} />
                    <AppText variant={GlobalStyleAttributes.textVariants.heading3} boldFont>{t('text-messages-balance')}</AppText>
                </HStack>
                <Stack style={{ gap: isMobile ? 0 : '0.2rem' }} alignItems={isMobile ? 'start' : 'center'} flexDirection={isMobile ? 'column' : 'row'}>
                    {smsBalance !== null ? <AppText>{`${t('your-text-message-balance')}: ${smsBalance?.toLocaleString(getLocale()) || 0}.`}</AppText> : <Spinner color={Colors.newDesign.darkGrey}/>}
                    <Box width={'max-content'}>
                    <CustomButtonV2 onClick={() => window.open('/reports-v5/communication?action=smsPurchasePage', '_blank')}
                                    type={'link'}
                                    style={{ width: 'max-content' }}
                                    text={t('purchase-more')}
                                    textProps={{ color: Colors.newDesign.mainBlue }}
                    />
                    </Box>
                </Stack>
            </Box>}
            {/* target audience */}
            {targetUser?.type === 'reports' ?
                <FormInput
                    label={t('targetAudience')}
                    value={t('membersChosen', {membersNum: specificTarget.users.length})}
                    width={'100%'}
                    containerProps={{ mb: '2rem' }}
                    helper={has('groupActions') && t('target-audience-group-helper')}
                    disabled
                />
            :
                <FormInput
                    label={t('targetAudience')}
                    width={'100%'}
                    containerProps={{ mb: '2rem', display: !!targetUser?.user ? 'none' : undefined }}
                    infoTooltip={specificTarget && !isMobile &&  t(`select-users-${values.type}-tooltip`)}
                    helper={has('groupActions') && t('target-audience-group-helper')}
                    error={specificTarget ? !values.users?.filter(u => u.isSelected).length && t(`select-at-least-one-${values.type}`) : (touched.class_name && errors.class_name ? errors.class_name : null)}>
                    {specificTarget ?
                        <Box borderColor={Colors.borderColor} borderWidth={1} borderRadius={4} paddingY={'0.4rem'} paddingX={'0.7rem'}>
                            <CustomButtonV2 onClick={() => setShowMemberlist(!showMemberlist)}
                                            override={() => (
                                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
                                                    <AppText>{specificTarget?.usersType}</AppText>
                                                    <FontAwesomeIcon color={Colors.newDesign.darkGrey} icon={showMemberlist ? light('angle-up') : light('angle-down')} size={GlobalConsts.iconSize.XS} />
                                                </div>
                                            )}
                            />
                            {showMemberlist &&
                                <ScrollView maxHeight={'10rem'}>
                                    <Box flexWrap={'wrap'} flexDir={'row'} mt={'0.8rem'}>
                                        {specificTarget.users.map((u, i) => (
                                            <Box width={isMobile ? '100%' : '50%'}>
                                                <CustomCheckbox text={`${u.first_name || ''} ${u.last_name || ''}`} isChecked={isSelected(i)} onChange={() => selectUser(u, i)}/>
                                            </Box>
                                        ))}
                                    </Box>
                                </ScrollView>}
                        </Box> :
                    <AdvanceSelect isDisabled={!!targetUser?.user} dontSort placeholder={t('target-audience-select')} options={targetOptions} initValue={values.class_name} onChange={(option) => setFieldValue('class_name', option.value ?? null)} isError={(specificTarget && !values.users?.filter(u => u.isSelected).length) || (touched.class_name && errors.class_name)}/>}
                </FormInput>
            }
            {/* selecte client */}
            {values.class_name === 'PrivateMessage' && <FormInput
                label={t('specific-user')}
                width={'100%'}
                error={touched.users && (typeof errors.users === 'string' ? errors.users : (errors.users?.[0]) )|| null}
                helper={has('groupActions') && [1,6,8].includes(target?.user?.child) && t('target-audience-group-helper')}
                containerProps={{ mb: '2rem' }}>
                {targetUser?.user ?
                <SimpleInput value={targetUser.user?.full_name} disabled status={errors.users && touched.users ? 'error' : null}/> :
                <AdvanceSelect placeholder={t('specific-user-select')} initValue={user} allowInactive type={USERS_SELECT} onChange={selectUser} hideLeadType={values.type === PUSH} hideFreefitType hideCustomerId isClearable={true} isError={touched.users && errors.users}/>
                }
            </FormInput>}
            {/* location */}
            {locationOptions.length > 2 && values.class_name !== 'PrivateMessage' && !specificTarget &&
                <FormInput containerProps={{ mb: '2rem' }} label={t('location')} width={'100%'} error={(touched.locations_box_fk && errors.locations_box_fk) ? errors.locations_box_fk : null}>
                    <AdvanceSelect dontSort options={locationOptions} initValue={values.locations_box_fk} onChange={(option) => setFieldValue('locations_box_fk', option.value ?? null)} isError={touched.locations_box_fk && errors.locations_box_fk}/>
                </FormInput>}
            {/* sender id */}
            {(values.type === EMAIL || (values.type === SMS && isIL && !box.sms_display_phone)) && <FormInput
                label={t(`sender-id-${values.type}`)}
                value={values.senderID}
                onChangeCallback={handleChange('senderID')}
                width={'100%'}
                containerProps={{ mb: '2rem' }}
                infoTooltip={!isMobile && t(`sender-id-${values.type}-tooltip`)}
                placeholder={t(`sender-id-${values.type}-desc${isMobile ? '-mobile' : ''}`)}
                disabled={!has('viewSettingsBusinessSettings')}
                maxLength={values.type === SMS && 11}
                error={(touched.senderID && errors.senderID || null)}
                showCount={values.type === SMS}
                topEndElement={values.type === SMS && values.senderID !== values.verifiedSenderID && /^\d+$/.test(values.senderID) &&
                    <CustomButtonV2 onClick={openVerify} type={'link'} text={t('verify-number')} textProps={{color: Colors.newDesign.mainBlue, fontSize: GlobalStyleAttributes.fonts.heading4}}/>}
            />}
            {/* template */}
            {<FormInput containerProps={{ mb: '2rem' }} label={t('template')} width={'100%'} >
                    <AdvanceSelect dontSort type={MESSAGE_TEMPLATE_SELECT} options={messageTempaltes} initValue={selectedTemplate} onChange={onChooseTemplate} />
                </FormInput>}
            {/* subject */}
            {values.type === EMAIL && <FormInput
                label={t('subject')}
                value={values.subject}
                onChangeCallback={handleChange('subject')}
                error={(touched.subject && errors.subject) || null}
                width={'100%'}
                maxLength={40}
                showCount
                containerProps={{ mb: '2rem' }}
                placeholder={t(`subject-${values.type}`)}
            />}
            {/* message content */}
            <FormInput label={t('messageContent')} width={'100%'} containerProps={{ mb: '3rem' }}
                helper={<Flex vertical>
                    {values.type === SMS &&<AppText color={Colors.newDesign.darkGrey} fontSize={GlobalStyleAttributes.fonts.infoText}>{t('sms-message-content-helper')}</AppText>}
                    {values.digital_form_id &&<AppText color={Colors.newDesign.darkGrey} fontSize={GlobalStyleAttributes.fonts.infoText}>{t('digital-form-message-content-helper')}</AppText>}
                </Flex>}
                error={(touched.messageContent && errors.messageContent) ? errors.messageContent : null}
                topEndElement={<AppText variant={GlobalStyleAttributes.textVariants.heading4} color={Colors.newDesign.darkGrey}>{`${charCount % maxCharCount}/${maxCharCount}${values.type === SMS ? ` | ${parseInt(charCount / maxCharCount) + 1} ${t('messages')}` : ''}`}</AppText>}>
                <Box>
                    <HStack borderColor={Colors.borderColor} flexWrap={'wrap'} borderWidth={1} p={'0.3rem'} space={'0.3rem'} borderRadius={4} top={'1px'} alignItems={'center'}>
                        <AppText variant={GlobalStyleAttributes.textVariants.heading4}>{`${t('tags')}:`}</AppText>
                        {tags.map(tag =>
                            tag.id === 'space' ? has('editSpace') &&
                            (<Button
                                bgColor={Colors.newDesign.menuBar}
                                borderColor={Colors.newDesign.menuBar}
                                onPress={() => onTagClick(tag.label)}
                                key={tag.id}
                                width={'auto'}
                                mb={'0.3rem'}
                                size={'md'} h={8}>
                                <AppText variant={GlobalStyleAttributes.textVariants.heading4}>{tag.label}</AppText>
                            </Button>) :
                                (<Button
                                    bgColor={Colors.newDesign.menuBar}
                                    borderColor={Colors.newDesign.menuBar}
                                    onPress={() => onTagClick(tag.label)}
                                    key={tag.id}
                                    width={'auto'}
                                    mb={'0.3rem'}
                                    size={'md'} h={8}>
                                    <AppText variant={GlobalStyleAttributes.textVariants.heading4}>{tag.label}</AppText>
                                </Button>)
                        )}
                    </HStack>
                    <SimpleInput inputType={'multiline'}
                                 rows={6}
                                 id={'text-area-message'}
                                 maxLength={values.type === SMS ? 320 : maxCharCount}
                                 placeholder={t('add-message-here')}
                                 value={values.messageContent}
                                 onChangeCallback={(val) => setFieldValue('messageContent', val)}
                                 status={touched.messageContent && errors.messageContent ? 'error' : null}
                    />
                </Box>
            </FormInput>
            {/* send me a test  */}
            <HStack>
                <CustomButtonV2 onClick={onSendMePress}
                                iconProps={{iconColor: Colors.newDesign.mainBlue, iconHoverColor: Colors.newDesign.mainBlueHover}}
                                override={({color}) => (
                                    <HStack space={'0.2rem'} alignItems={'center'}>
                                        <FontAwesomeIcon color={color} icon={light('paper-plane')} size={GlobalConsts.iconSize.XS} />
                                        <AppText color={color}>{t('send-me-a-test')}</AppText>
                                    </HStack>)}
                />
            </HStack>
            {showVerifyNotification && <ConfirmModal
                notAnError
                isOpen={showVerifyNotification}
                header={showVerifyNotification?.header}
                subheader={showVerifyNotification?.subheader}
                confirmText={showVerifyNotification?.confirmText}
                cancelText={showVerifyNotification?.cancelText}
                onConfirm={showVerifyNotification?.onConfirm}
                onCancel={showVerifyNotification?.onCancel}
                onClose={() => setShowVerifyNotification(null)} />}
        </>
    );
};

export default MessageDetails;
