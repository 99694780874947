import * as React from "react"
import Svg, { Path } from "react-native-svg"

const IconGoogle = (props) => (
  <Svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    {...props}
  >
    <Path
      d="M19.08 8.786h-.705V8.75H10.5v3.5h4.945A5.248 5.248 0 0 1 5.25 10.5c0-2.9 2.35-5.25 5.25-5.25 1.338 0 2.556.505 3.483 1.33l2.475-2.475A8.71 8.71 0 0 0 10.5 1.75a8.75 8.75 0 1 0 8.58 7.036Z"
      fill="#FFC107"
    />
    <Path
      d="m2.759 6.427 2.875 2.109A5.248 5.248 0 0 1 10.5 5.25c1.338 0 2.556.505 3.483 1.33l2.475-2.475A8.71 8.71 0 0 0 10.5 1.75a8.745 8.745 0 0 0-7.741 4.677Z"
      fill="#FF3D00"
    />
    <Path
      d="M10.5 19.25a8.71 8.71 0 0 0 5.866-2.271l-2.708-2.292A5.21 5.21 0 0 1 10.5 15.75a5.248 5.248 0 0 1-4.936-3.476L2.71 14.472a8.743 8.743 0 0 0 7.79 4.778Z"
      fill="#4CAF50"
    />
    <Path
      d="M19.08 8.786h-.705V8.75H10.5v3.5h4.945a5.268 5.268 0 0 1-1.788 2.437h.001l2.708 2.291c-.191.174 2.884-2.103 2.884-6.478 0-.587-.06-1.16-.17-1.714Z"
      fill="#1976D2"
    />
  </Svg>
)

export default IconGoogle;